// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-nav {
    background-color: #220B21!important;
    padding-top: 35px!important;
    padding-bottom: 35px!important;
    position: absolute;
    /* bottom: 0px; */
    width: 100%;
    color: white;
}

.MuiBottomNavigationAction-label{
    padding-top: 3px!important;
    opacity: 1 !important; 
    color: white !important; 
}

.bottom-nav-icon-img{
    width: 30px;
}

.bottom-nav-icon-img-arrived {
    width: 55px;
}

/* Sticky position for mobile screens */
@media (max-width: 768px) {
    .bottom-nav {
        position: fixed;
        bottom: 0; 
        left: 0;
        right: 0;
        z-index: 1000; 
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Nav/BottomNav.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,2BAA2B;IAC3B,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA,uCAAuC;AACvC;IACI;QACI,eAAe;QACf,SAAS;QACT,OAAO;QACP,QAAQ;QACR,aAAa;IACjB;AACJ","sourcesContent":[".bottom-nav {\n    background-color: #220B21!important;\n    padding-top: 35px!important;\n    padding-bottom: 35px!important;\n    position: absolute;\n    /* bottom: 0px; */\n    width: 100%;\n    color: white;\n}\n\n.MuiBottomNavigationAction-label{\n    padding-top: 3px!important;\n    opacity: 1 !important; \n    color: white !important; \n}\n\n.bottom-nav-icon-img{\n    width: 30px;\n}\n\n.bottom-nav-icon-img-arrived {\n    width: 55px;\n}\n\n/* Sticky position for mobile screens */\n@media (max-width: 768px) {\n    .bottom-nav {\n        position: fixed;\n        bottom: 0; \n        left: 0;\n        right: 0;\n        z-index: 1000; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
