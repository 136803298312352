// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-sec-nollywood-page{
    background-color: #381837;
    height: 100vh;
}

.country-card:hover{
    border-radius: 20px;
    background-color: #d45669!important;
    border: 0px solid;
}

.country-card{
    border-radius: 20px;
    height: 160px;
}

.afrime-card{
    background: no-repeat;
}

.prompt{
    color: white;
    font-weight: bold;
    transition: ease-in-out 3000ms;
    font-style: normal;
}

/* Be careful of this, cause applies to all pages/components now */
.modal-backdrop.show{
    /* opacity: 0; */
}

@keyframes slide {
    0%{
        top:0px
    }

    50%{
        bottom: 15px;
    }

    100%{
        top:0px
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MoviesPage/NollyWoodPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,mCAAmC;IACnC,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA,kEAAkE;AAClE;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI;IACJ;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI;IACJ;AACJ","sourcesContent":[".bg-sec-nollywood-page{\n    background-color: #381837;\n    height: 100vh;\n}\n\n.country-card:hover{\n    border-radius: 20px;\n    background-color: #d45669!important;\n    border: 0px solid;\n}\n\n.country-card{\n    border-radius: 20px;\n    height: 160px;\n}\n\n.afrime-card{\n    background: no-repeat;\n}\n\n.prompt{\n    color: white;\n    font-weight: bold;\n    transition: ease-in-out 3000ms;\n    font-style: normal;\n}\n\n/* Be careful of this, cause applies to all pages/components now */\n.modal-backdrop.show{\n    /* opacity: 0; */\n}\n\n@keyframes slide {\n    0%{\n        top:0px\n    }\n\n    50%{\n        bottom: 15px;\n    }\n\n    100%{\n        top:0px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
