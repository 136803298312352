import React, {useEffect, useState, useContext} from 'react';
import {} from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import NavBarSearch from '../Nav/TopNavBarV2';
import MovieCard from '../MoviesPage/MovieCardComponent';
import Footer from '../Footer/Footer';
import ReactPlayer from 'react-player';


const AfrimePage=(props)=>{

	const { isAfrimeLightTheme, afrimeDarkTheme, afrimeLightTheme, changeTheme}  = useContext(ThemeContext);
	const theme = isAfrimeLightTheme ? afrimeLightTheme : afrimeDarkTheme


	useEffect(()=>{
		if(!window.location.hash) {
			window.location = window.location + '#loaded';
			window.location.reload();
		}
	},[])

	return(
		<div style={{ background:theme.background, color:theme.color, backgroundAttachment:'fixed', transition:'1s ease-in-out' }} className="">
			<NavBarSearch/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						{/* <button onClick={changeTheme}>Dark Theme</button> */}
						<ReactPlayer
							url="https://www.youtube.com/watch?v=r0wVQLGojmY"
							controls={true}
							height="90vh"
						/>
					</div>
				</div>
			</div>


			<div className="container-fluid">
				<div className="row py-md-4 watchingNow">
					<div className="col-md-12">
						<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">Watching Now</h1>
					</div>
					{/* Map (for each) watching now */}
					<div className="col-md-3">
						{/* Get image fetch()*/}
						<MovieCard image="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620560269/AfroWatch/Rectangle_20_whmici.png"/>
					</div>

					<div className="col-md-3">
						<MovieCard image="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620560269/AfroWatch/Rectangle_13_igt9md.png"/>
					</div>
				</div>
			</div>

			<Footer/>
		</div>
	)
}

export default AfrimePage;