import { axiosInstance } from "../apiClient";
import {useNavigate} from 'react-router-dom';

const useApiErrorHandler = () => {
	const navigate = useNavigate();

	const logOut= async()=>{
		const resp = await axiosInstance.get('/auth/logout')
		console.log(resp.message);
		
		if(resp.data.success){
			localStorage.clear();
			navigate('/login')
		}
	}

	const handler = (error) => {
		const {message} = error.response.data;

		if(message){
			switch (message) {
			case "Token expired":
				logOut();
				break;

			default:
				console.log(error);
				break;
			}
		}
	
	}

	return handler;
}

export default useApiErrorHandler;