import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Video from "./Video";
import "./index.css";
import VideoSidebar from "./VideoSidebar";
import getUser from '../../utils/user';

function ShortIndex() {
	const navigate = useNavigate()
	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const [shortVideos, setShortVidoes] = useState([]);
	const [shortVideoContent, setShortVidoeContent] = useState([]);
	const [shortVideoStream, setShortVidoeStream] = useState([]);
	const userId = localStorage.getItem("currentUser");

	useEffect(()=>{
		getShortVideos();
		//Determine Mobile or not
	},[])

	
	const getShortVideos = async ()=>{
		const res = await axios_.get(`/${userId}/afclips/stream`)
		const { data } = res.data
		if(res.status === 200){
			setShortVidoes(data);
			setShortVidoeContent(data.content);
			setShortVidoeStream(data.stream);
		}else{
			console.log(res.data.message)
		}
	}

	// Function to merge arrays by ID
	function mergeContentAndStreamBySameId(videoContentList, videoStreamList) {
		const merged = [];
  
		// Merge items with the same ID
		videoContentList?.length > 0 && videoContentList.forEach(content => {
			const newContentStream = videoStreamList.find(stream => stream.content_id === content.content_id);
			if (newContentStream) {
				merged.push({ ...content, ...newContentStream });
			} else {
				merged.push(content);
			}
		});
  
		// Add remaining items from videoStreamList
		videoStreamList?.length > 0 && videoStreamList.forEach(stream => {
			const content = videoContentList.find(content => content.id === stream.id);
			if (!content) {
				merged.push(stream);
			}
		});
  
		return merged;
	}
  
	const mergedArray = mergeContentAndStreamBySameId(shortVideoContent, shortVideoStream);

	const newVideos = mergedArray && mergedArray?.map((item) => {
		return {
			id: item.content_id,
			url: item.hls_stream_path,
			animators: item.animators && item.animators.map((anime) => anime).join(", "),
			audio: item.audio_path, 
			description: item.content_description,
			likes: item.likes ? item.likes : 0, 
			comments: item.comments || [{}],
			shares: 0, //Later feature
			video_name: item.video_name,
			video_path: item.video_path
		}
	})

	const checkUserPlanExists =  async () => {
		const user =  await getUser();
		if (user) {
			if (user.currentPlan === null) {
				navigate('/pricing-plan', { state: { message: 'You need a plan to view content 😊' }})
			} 
		}
	};

	useEffect(()=>{
		checkUserPlanExists();
		//only if you have a plan
		//setTimeout(()=>{navigate('/whos-watching')} ,2000)
	},[])
	return (
		<>

			{
				newVideos && newVideos.length > 0 ? 
					<div className="app">
						<div className="app__videos">
							{newVideos &&  newVideos.map(
								({
									id,
									url,
									animators,
									description,
									audio,
									likes,
									comments,
									shares,
								}) => (
									<Video
										key={id}
										url={url}
										animators={animators}
										audio={audio}
										likes={likes}
										comments={comments}
										description={description}
										shares={shares}
										contentId={id}
									/>
								)
							)
							}
						</div>
					</div>
					:
					<div className='row justify-content-center'>
						<div className='col-md-6'>
							<div>
								<img className='empty_list_Box' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1717212230/Empty-amico_1_kfwaoz.png" alt='empty_list_box'/>
								<h4 className='text-center pt-5 pb-2 watchlist_Text text-white'>No Clip added yet!! </h4>
							</div>
						</div>
					</div>
			}
		</>
	);
}

export default ShortIndex;
