// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.country-card-wrapper {
  background: #2D2640;
  border-radius: 8px;
  padding: 2px;
}

.flag-container {
  width: 48px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 12px;
}

.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/MoviesPage/CountryCard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".country-card-wrapper {\n  background: #2D2640;\n  border-radius: 8px;\n  padding: 2px;\n}\n\n.flag-container {\n  width: 48px;\n  height: 32px;\n  border-radius: 4px;\n  overflow: hidden;\n  margin-right: 12px;\n}\n\n.flag-image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
