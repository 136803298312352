import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { styled } from "@mui/system";
import "./customaccordion.css";

const CustomAccordion = styled(Accordion)({
	backgroundColor: "#331832B2",
	borderRadius: "15px",
	marginBottom: "15px",
	color: "white"
});

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	backgroundColor:"#331832B2",
	flexDirection: "row",
	"& .MuiAccordionSummary-expandIconWrapper": {
		marginRight: theme.spacing(1),
		justifyContent: "space-between",
		paddingBottom: '10px'
	},
	display: "flex",
	justifyContent: "space-between !important",
	borderRadius: "15px",
	width: '100%'
}));

const CustomAccordionDetails = styled(AccordionDetails)({
	backgroundColor: "#331832B2",
	borderRadius: "15px",
});

const CustomAccordionItem = ({
	expanded,
	onChange,
	panel,
	details,
	number,
	plan,
	user,
	current,
	price,
}) => {
	return (

		<CustomAccordion
			expanded={expanded === panel}
			onChange={onChange(panel)}
		>
			<CustomAccordionSummary
				aria-controls={`${panel}d-content`}
				id={`${panel}d-header`}
			>
				<div style={{display: 'flex' , justifyContent: 'space-between', width: '100%'}}>
					<div style={{ display: "flex"}}>
						{expanded && expanded === panel  ? (
							<RadioButtonCheckedIcon  sx={{ fontSize: "1.3rem" }} />
						) : (
							<RadioButtonUncheckedIcon sx={{ fontSize: "1.3rem" }} />
						)}
						<div>
							<Typography
								sx={{
									flex: "1",
									marginLeft: "10px",
									marginBottom: "4px",
									fontFamily: `'Poppins', sans-serif`,
									fontSize: "16px",
									fontWeight: 600,
									marginRight: "10px"
								}}
							>
								{plan}
							</Typography>
							<Typography
								sx={{
									flex: "1",
									marginLeft: "10px",
									marginBottom: "4px",
									fontFamily: `'Poppins', sans-serif`,
									fontSize: "14px",
									fontWeight: 500,
								}}
							>
								{user}
							</Typography>
							{
								current && 
						<Typography
							sx={{
								flex: "1",
								marginLeft: "10px",
								marginBottom: "4px",
								color: "#C170C1",
								fontFamily: `'Poppins', sans-serif`,
								fontSize: "14px",
								fontWeight: 600,
							}}
						>
							{current}
						</Typography>
							}
						</div>
					</div>
					<div>

						{number && (
							<div>
								<Typography
									sx={{
										marginBottom: "4px",
										fontFamily: `'Poppins', sans-serif`,
										fontSize: "16px",
										fontWeight: 600,
									}}
								>
									{price}
								</Typography>
								<Typography
									sx={{
										marginBottom: "4px",
										color: "#A4A4A4",
										fontFamily: `'Poppins', sans-serif`,
										fontSize: "14px",
										fontWeight: 500,
									}}
								>
                            Per month
								</Typography>
							</div>
						)}
					</div>
				</div>
			</CustomAccordionSummary>
			<CustomAccordionDetails>{details}</CustomAccordionDetails>
		</CustomAccordion>
	);
};

export default CustomAccordionItem;
