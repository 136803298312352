// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-listPage {
    background: url("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png"), #311430;
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    /* padding-top: 5.4rem; */
    background-color: #2D132C;
}`, "",{"version":3,"sources":["webpack://./src/components/NewArrivals/index.css"],"names":[],"mappings":"AAAA;IACI,oHAAoH;IACpH,4BAA4B;IAC5B,yBAAyB;IACzB,sBAAsB;IACtB,yBAAyB;IACzB,yBAAyB;AAC7B","sourcesContent":[".bg-listPage {\n    background: url(\"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png\"), #311430;\n    background-repeat: no-repeat;\n    background-position: left;\n    background-size: cover;\n    /* padding-top: 5.4rem; */\n    background-color: #2D132C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
