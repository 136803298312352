import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import videojs from 'video.js';
import "video.js/dist/video-js.css";
import "videojs-mux";
// import { debounce } from 'lodash';
import AfrowatchVideoPlayer from "./AfrowatchVideoJS";
import MovieSliderSimilarMovies from "../Slider/MovieSliderSimilarMovies";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../apiClient";
import sampleStreamApiResponse from "../../examples/streamResponse.json";
import { resolveSourceTypeFromVideoType } from "./controls/_utils";
import TopNavBar from "../Nav/TopNavBarV2";
import BottomNav from "../Nav/BottomNav";
import EpisodeSelector from '../MovieDescription/EpisodeSelector';
import "../MoviesPage/MoviesPage.css";
import "./MediaPlayer.css";

const MediaPlayerPage = (props) => {
	const location = useLocation();
	const [mediaStream, setMediaStream] = useState({});
	const [featuredStream, setFeaturedStream] = useState();
	const [isMoviesComp, setComp] = useState(false);
	const [isNavNotShowing, showNav] = useState(true);
	const [pageloading, setPageLoading] = useState(false);

	//Series
	const [selectedSeason, setSelectedSeason] = useState(1);  // Default to season 1
	const [selectedEpisode, setSelectedEpisode] = useState(1);  // Default to episode 1
	const [episodeData, setEpisodeData] = useState(null);  // Store specific episode data

	const playerRef = useRef(null);
	const videoRef = useRef(null)

	const userId = localStorage.getItem("currentUser"); //
	const profileId = localStorage.getItem("selected_prof_id");
	const profileName = localStorage.getItem("profile_name");
	const regionObj = localStorage.getItem("region") ? JSON.parse(localStorage.getItem("region")): {};
	const region = regionObj[profileId];
	const contentId = location.state?.contentId || "no-id";
	const actors = location.state?.actors || [];

	// When its the Default Page (Movies page)
	const regex = /^\/profile\/[a-f0-9]{24}\/watch\/?$/;
	let featurePage = regex.test(window.location.pathname)
	//

	const [videoJsOptions, setVideoJsOptions] = useState({
		autoplay: featurePage ? true : false,
		controls: true,
		responsive: true,
		fluid: true,
		enableSmoothSeeking: true,
		nativeTextTracks: true,
		preloadTextTracks: true,
		spatialNavigation: {
			enabled: true,
			horizontalSeek: true
		},
		userActions: {
			click: true,
			hotkeys: function (event) {
				// Your existing hotkeys implementation
				if (event.which === 86 || event.which === 70) {
					event.preventDefault();
					if (this.isFullscreen()) {
						this.exitFullscreen();
					} else {
						this.requestFullscreen();
					}
				}
				// Toggle mute/unmute when pressing the M key (77)
				if (event.which === 77) {
					event.preventDefault();
					this.muted(!this.muted());
				}
				// Check if the spacebar is pressed
				if (event.which === 32) {
					event.preventDefault(); // Prevent default spacebar action
					// Toggle play/pause
					if (this.paused()) {
						this.play();
					} else {
						this.pause();
					}
				}
			},
		},
		sources: [],  // Start with empty sources
		controlBar: {
			skipButtons: {
				forward: 10,
				backward: 10
			},
		},
		muted: featurePage ? true : false,
		plugins: {
			mux:{
				debug: false,
				disableCookies: true,
				data: {
					env_key: process.env.REACT_APP_MUX_TOKEN,
					player_name: "Afrowatch Player",
					viewer_user_id: userId, //currently userid not profileId
					viewer_profile_id: profileId,
					viewer_profile_name: profileName,
					// Player Metadata
					player_version: '1.0.0',
					// Video Metadata
					video_id:'',
					video_stream_type: 'on-demand',
					video_cdn: 'cloud-front',
					video_title: '',
					video_content_type: '',
					//subtitles in our case
					video_language_code: '',
					//animators
					video_producer: '',
					//we store our length in (s)
					video_duration: '',
					// You can only have 1-10 custom metadata and they have to be Strings
					//Custom 1: Stream id
					custom_1: '',
					//Custom 2 : creator id
					//Check if stream content id is in vendor list array, if it is set the creator id
					custom_2: ''
					//Custom 3: video Genre
					//custom_3: videoRef && videoRef.current?.genre
					//Custom 4: Mux playback id, to get playback from mux in portal
				}
			}
		}
	});

	function isMovieComponent() {
		const check = location.pathname.split("/");
		return !!(check.indexOf("watch") && check.indexOf("profile") !== -1);
	}

	async function getContentStreamInfo(userId, contentId) {
		setPageLoading(true);
		try {
			const resp = await axiosInstance.get(`${userId}/content/${contentId}/stream/`);

			if (resp.data.status === 200 && resp.data.content) {
				setPageLoading(false);
				console.log(resp.data.content, 'resp content data');
				return resp.data.content;
			}
		} catch (e) {
			if (window.location.host === "http://localhost:3000") {
				setPageLoading(false);
				console.log(`Error occured ${e}, Using default data for test environment`);
				return sampleStreamApiResponse.data.content;
			} else {
				return null;
			}
		}
	}

	useEffect(() => {
		setComp(isMovieComponent());
		//For video metadata
		getContentById()
	}, []);

	const getContentById = async () => {
		let res = await axiosInstance.get(`/content/${contentId}`)
		const { data } = res
		if (res.data.status === 200) {
			videoRef.current = data.data
		}
	}

	useEffect(() => {
		let contentStream
		const getStream = async () => {
			contentStream = await getContentStreamInfo(userId, contentId)

			if (contentStream) {
				setMediaStream(contentStream)
			}
		}
		getStream()
	}, [contentId])

	const [isFooter, setFooter] = useState({ footerActive: true,bottomNavActive: false,});

	useEffect(() => {
		if ($(window).width() <= 600) {
			setFooter({
				...isFooter,
				footerActive: false,
				bottomNavActive: true,
			});
		} else {
			setFooter({
				...isFooter,
				footerActive: true,
				bottomNavActive: false,
			});
		}
	}, [])

	const handleEpisodeSelect = (seasonNumber, episodeNumber) => {
		console.log(`User selected: Season ${seasonNumber}, Episode ${episodeNumber}`);
  
		// First set the season, then the episode to ensure the proper order of updates
		setSelectedSeason(parseInt(seasonNumber));
		
		// Using slight delay to ensure the season state has updated before changing the episode
		setTimeout(() => {
			setSelectedEpisode(parseInt(episodeNumber));
		}, 10);
	};

	// This effect processes the episode data when mediaStream or selected episode changes
	useEffect(() => {
		if (mediaStream?.stream?.isSeries && mediaStream?.stream?.seasons) {
			const seasons = mediaStream.stream.seasons;
			
			// Find the selected season
			if (seasons[selectedSeason]) {
				// a copy of the episodes array for the selected season
				const episodesInSeason = [...seasons[selectedSeason]];
		
				// Find the episode that matches the selected episode number
				const episodeObj = episodesInSeason.find(ep => ep.episode_number === selectedEpisode);
				
				if (episodeObj) {
					// Found the correct episode
					console.log(`Loading episode ${selectedEpisode} from season ${selectedSeason}`);
					setEpisodeData(episodeObj);
				} else if (episodesInSeason.length > 0) {
					// If episode not found, default to first episode in season
					console.log(`Episode ${selectedEpisode} not found in season ${selectedSeason}, defaulting to first episode`);
					setEpisodeData(episodesInSeason[0]);
					setSelectedEpisode(episodesInSeason[0].episode_number);
				}
			} else if (Object.keys(seasons).length > 0) {
				// If season not found, default to first season
				const firstSeasonKey = Object.keys(seasons)[0];
				setSelectedSeason(parseInt(firstSeasonKey));
				console.log(`Season ${selectedSeason} not found, defaulting to season ${firstSeasonKey}`);
				
				if (seasons[firstSeasonKey].length > 0) {
					setEpisodeData(seasons[firstSeasonKey][0]);
					setSelectedEpisode(seasons[firstSeasonKey][0].episode_number);
				}
			}
		}
	}, [mediaStream, selectedSeason, selectedEpisode]);
  
	// This effect updates the player when episodeData changes
	useEffect(() => {
		// Only attempt to update the player if it exists and is ready
		if (playerRef.current && episodeData && typeof playerRef.current.src === 'function') {
			try {
				console.log(`Updating player to season ${selectedSeason}, episode ${selectedEpisode}`);
				console.log("Episode data:", episodeData);
			
				// Create the new source URL
				const sourceUrl = episodeData.hls_stream_path ? 
					`${process.env.REACT_APP_BASE_STREAM_URL}/${episodeData.hls_stream_path}` :
					`${process.env.REACT_APP_BASE_STREAM_URL}/default-video-01/Default/HLS/01_720.m3u8`;
			
				// Update player source
				playerRef.current.src([{
					src: sourceUrl,
					type: mediaStream?.stream['video_content_type'] ? 
						resolveSourceTypeFromVideoType(mediaStream.stream['video_content_type']) : 
						'application/x-mpegURL'
				}]);
			
				// Only update text tracks if the player is still valid
				if (typeof playerRef.current.textTracks === 'function' && 
					typeof playerRef.current.removeRemoteTextTrack === 'function' &&
					typeof playerRef.current.addRemoteTextTrack === 'function') {
				
					// Subtitle tracks updates
					if (episodeData.subtitles_list && episodeData.subtitles_list.length > 0) {
						// Remove existing text tracks
						const existingTracks = playerRef.current.textTracks();
				
						for (let i = existingTracks.length - 1; i >= 0; i--) {
							playerRef.current.removeRemoteTextTrack(existingTracks[i]);
						}
						
						// Add new text tracks
						episodeData.subtitles_list.forEach(subtitle => {
							playerRef.current.addRemoteTextTrack({
								src: subtitle.src,
								kind: 'subtitles',
								srclang: subtitle.srclang,
								label: subtitle.label,
								default: subtitle.default
							}, false);
						});
					}
				}
			
				// Update video metadata if mux is available
				if (playerRef.current.mux && typeof playerRef.current.mux.emit === 'function') {
					playerRef.current.mux.emit('videochange', {
						video_id: episodeData.video_episode_id,
						video_title: `${videoRef.current?.content_name || episodeData?.episode_name} - S${selectedSeason}E${selectedEpisode}`,
					});
				}
			
				// Only try to play if the play method exists
				if (typeof playerRef.current.play === 'function') {
					playerRef.current.play().catch(e => console.log('Playback prevented:', e));
				}
			} catch (error) {
				console.error("Error updating video player:", error);
			}
		} else {
			console.log("Player or episode data not ready for update");
			if (!playerRef.current) {
				console.log("Player reference is null");
			} else if (typeof playerRef.current.src !== 'function') {
				console.log("Player doesn't have src method");
			}
			if (!episodeData) {
				console.log("No episode data available");
			}
		}
	}, [episodeData]);
	
	// Optional optimization: multiple state updates could potentially cause too many player reloads,
	// a debounce function has been implemented to only update the player after state has settled:
	
	// const debouncedPlayerUpdate = useCallback(
	// 	debounce((episode) => {
	// 		//player update
	// 		if (playerRef.current && episode) {
	// 			playerRef.current.src([{
	// 				src: episode.hls_stream_path,
	// 				type: mediaStream?.stream['video_content_type'] ? resolveSourceTypeFromVideoType(mediaStream.stream['video_content_type']) : 'application/x-mpegURL'
	// 			}]);
	// 		}
	// 	}, 300),
	// 	[playerRef]
	// );
	
	// useEffect(() => {
	// 	if (episodeData) {
	// 		debouncedPlayerUpdate(episodeData);
	// 	}
	// }, [episodeData, debouncedPlayerUpdate]);

	let showNavigation = () => showNav(!isNavNotShowing);

	//Effect to update the options when mediaStream changes, allows the player to update properly when episode changes
	useEffect(() => {
		if (mediaStream?.stream) {
			// Determine the source based on whether it's a series with episodeData
			let src, videoId, videoTitle;
		
			if (mediaStream.stream.isSeries && episodeData) {
				// Series with episode data
				src = episodeData.hls_stream_path ? 
					`${process.env.REACT_APP_BASE_STREAM_URL}/${episodeData.hls_stream_path}` :
					`${process.env.REACT_APP_BASE_STREAM_URL}/default-video-01/Default/HLS/01_720.m3u8`;
				
				videoId = episodeData.video_episode_id;
				videoTitle = `${videoRef.current?.content_name || episodeData?.episode_name} - S${selectedSeason}E${selectedEpisode}`;
			} else {
				// Regular content
				src = `${process.env.REACT_APP_BASE_STREAM_URL}/${mediaStream.stream['hls_stream_path'] || 'default-video-01/Default/HLS/01_720.m3u8'}`;
				videoId = mediaStream.stream.content_id;
				videoTitle = videoRef.current?.content_name || '';
			}
		
			// Update the options
			setVideoJsOptions(prev => ({
				...prev,
				sources: [{
					src: src,
					type: mediaStream.stream['video_content_type'] ? 
						resolveSourceTypeFromVideoType(mediaStream.stream['video_content_type']) : 
						'application/x-mpegURL'
				}],
				plugins: {
					...prev.plugins,
					mux: {
						...prev.plugins.mux,
						data: {
							...prev.plugins.mux.data,
							video_id: videoId,
							video_title: videoTitle,
							video_content_type: videoRef.current?.content_type || '',
							video_language_code: videoRef.current?.subtitles?.toString() || '',
							video_producer: videoRef.current?.animators?.toString() || '',
							video_duration: videoRef.current?.length || 0,
							custom_1: mediaStream.stream.stream_id || '',
							custom_2: videoRef.current?.creator_id || ''
						}
					}
				}
			}));
		}
	}, [mediaStream, episodeData, selectedSeason, selectedEpisode]);
  
	// Language 
	const defaultLanguage = { code: 'en', label: 'English' };

	//update tracks to use episode-specific subtitles when available
	let tracks = []
	try {
		tracks = mediaStream?.stream.isSeries && episodeData?.subtitles_list ? episodeData.subtitles_list : 
			(mediaStream?.stream['subtitles_list'] || [
				{
					src: 'https://res.cloudinary.com/dz5b76kzm/raw/upload/v1711535218/subtitles/en_htuk8r.vtt',
					kind: 'subtitles',
					srclang: 'en', // Source language
					label: 'English',
					//default: true,
				},
				{
					src: 'https://res.cloudinary.com/dz5b76kzm/raw/upload/v1711535187/subtitles/yo_lyl7dp.vtt',
					kind: 'subtitles',
					srclang: 'yb', // Source language
					label: 'Yoruba',
				},
			]);
	} catch (error) {
		console.log(error)
	}

	// const handlePlayerReady = (player) => {
	// 	playerRef.current = player;
	// 	// You can handle player events here, for example:
	// 	player.on('waiting', () => {
	// 		videojs.log('player is waiting');
	// 	});

	// 	player.on('dispose', () => {
	// 		videojs.log('player will dispose');
	// 	});
	// };

	//Enhanced handlePlayerReady
	const handlePlayerReady = (player) => {
		console.log('Player is ready and being set to ref');
		playerRef.current = player;
		
		// Flag to track if player is valid
		player.isReady = true;
		
		// You can handle player events here
		player.on('waiting', () => {
			videojs.log('Player is waiting');
		});
	
		player.on('error', (error) => {
			console.error('Player error:', error);
		});
		
		player.on('dispose', () => {
			console.log('Player is being disposed');
			// Clear the reference when player is disposed
			if (playerRef.current === player) {
				console.log('Clearing player reference');
				playerRef.current = null;
			}
		});
		
		// If we already have episode data, update the player
		if (episodeData) {
			console.log('Player is ready and episode data exists - updating source immediately');
			updatePlayerSource(episodeData);
		}
	};
  
	// separate helper function to update player source (more reliable)
	const updatePlayerSource = (episodeData) => {
		if (!episodeData) return;
		if (!playerRef.current || typeof playerRef.current.src !== 'function') {
			console.log('Cannot update player source - player not ready');
			return;
		}
		
		try {
			console.log(`Updating player source with episode ${episodeData.episode_number}`);
			
			// Create the source URL
			const sourceUrl = episodeData.hls_stream_path ? 
				`${process.env.REACT_APP_BASE_STREAM_URL}/${episodeData.hls_stream_path}` :
				`${process.env.REACT_APP_BASE_STREAM_URL}/default-video-01/Default/HLS/01_720.m3u8`;
			
			// Update player source
			playerRef.current.src([{
				src: sourceUrl,
				type: mediaStream?.stream['video_content_type'] ? 
					resolveSourceTypeFromVideoType(mediaStream.stream['video_content_type']) : 
					'application/x-mpegURL'
			}]);
		
			console.log('Player source updated successfully');
		} catch (error) {
			console.error('Error updating player source:', error);
		}
	};
  
	//Using episodeData update function in existing useEffect
	useEffect(() => {
		if (episodeData) {
			// Short delay to ensure the player is properly initialized
			const timeoutId = setTimeout(() => {
				updatePlayerSource(episodeData);
			}, 50);
			
			return () => clearTimeout(timeoutId);
		}
	}, [episodeData]);

	// A cleanup effect to properly dispose the player on unmount
	useEffect(() => {
		return () => {
			console.log('MediaPlayerPage unmounting - cleaning up player');
			if (playerRef.current && typeof playerRef.current.dispose === 'function') {
				console.log('Disposing player on component unmount');
				try {
					playerRef.current.dispose();
				} catch (e) {
					console.error('Error disposing player:', e);
				}
				playerRef.current = null;
			}
		};
	}, []);

	return (
		<div className="video-player-page">
			{isMoviesComp ? null : <TopNavBar region={region} profile={profileName} navToggle={showNavigation} />}

			{
				pageloading ?
					<div className="no-video">
						<LinearProgress disableShrink className="d-block m-auto" color="secondary" fourColor style={{}} />
						<div data-vjs-player>
							<div className="video-js" />
						</div>
					</div>
					:
					<AfrowatchVideoPlayer 
						contentName={videoRef.current?.content_name} 
						streamData={mediaStream}
						options={videoJsOptions}
						onReady={handlePlayerReady} 
						tracks={tracks} 
						language={defaultLanguage} 
						navDrawer={props.navDrawer} 
					/>
			}

			{/* Episode Selector for Series */}
			{mediaStream?.stream?.isSeries && mediaStream?.stream?.seasons && (
				<div className="container-fluid mt-3">
					<EpisodeSelector
						seasons={mediaStream.stream.seasons}
						selectedSeason={selectedSeason}
						selectedEpisode={selectedEpisode}
						onSeasonChange={setSelectedSeason}
						onEpisodeSelect={handleEpisodeSelect}
					/>
				</div>
			)}

			{isMoviesComp ? null : (
				<div>
					<div className="container-fluid">
						<div className="row mt-5">
							<div className="col-md-12">
								<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">
									Similar Animation
								</h1>
							</div>
						</div>

						{actors.length > 0 ? (
							<MovieSliderSimilarMovies actors={actors} />
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="text-center text-white">
											No similar movies found
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			{isMoviesComp ? null : (isFooter.footerActive ? <Footer /> : (<BottomNav />))}
		</div>
	);
};

export default MediaPlayerPage;