// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-width:600px){
    .bg-downloadsPage{
        background: url("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png"), #311430; 
        background-position: left;
        height: 100vh;
    }
}

.bg-downloadsPage{
    background: url("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png"), #311430; 
    background-position: left;
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/components/DownloadPage/Downloads.css"],"names":[],"mappings":"AAAA;IACI;QACI,oHAAoH;QACpH,yBAAyB;QACzB,aAAa;IACjB;AACJ;;AAEA;IACI,oHAAoH;IACpH,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":["@media(max-width:600px){\n    .bg-downloadsPage{\n        background: url(\"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png\"), #311430; \n        background-position: left;\n        height: 100vh;\n    }\n}\n\n.bg-downloadsPage{\n    background: url(\"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png\"), #311430; \n    background-position: left;\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
