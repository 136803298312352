// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoSidebar {
  position: absolute;
  top: 40%;
  right: 10px;
  color: white;
  display: inline-block;
}
  
.videoSidebar__button {
  padding: 20px;
  text-align: center;
}

.css-tzssek-MuiSvgIcon-root {
  width: .7em !important;
}
  
  
@media(max-width:600px){
  .videoSidebar {
    position: absolute;
    /* top: 50%; */
    right: 10px;
    /* transform: translateY(-50%); */
    color: white;
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    z-index: 2; /* Adjust to ensure it doesn't overlap higher-z-index elements */
  }
  .videoSidebar__button {
    padding: 5px;
    text-align: center;
  }
}
  `, "",{"version":3,"sources":["webpack://./src/components/Shorts/VideoSidebar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;;AAGA;EACE;IACE,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,iCAAiC;IACjC,YAAY;IACZ,aAAa;IACb,4BAA4B;IAC5B,SAAS;IACT,UAAU,EAAE,gEAAgE;EAC9E;EACA;IACE,YAAY;IACZ,kBAAkB;EACpB;AACF","sourcesContent":[".videoSidebar {\n  position: absolute;\n  top: 40%;\n  right: 10px;\n  color: white;\n  display: inline-block;\n}\n  \n.videoSidebar__button {\n  padding: 20px;\n  text-align: center;\n}\n\n.css-tzssek-MuiSvgIcon-root {\n  width: .7em !important;\n}\n  \n  \n@media(max-width:600px){\n  .videoSidebar {\n    position: absolute;\n    /* top: 50%; */\n    right: 10px;\n    /* transform: translateY(-50%); */\n    color: white;\n    display: flex;\n    /* flex-direction: column; */\n    gap: 10px;\n    z-index: 2; /* Adjust to ensure it doesn't overlap higher-z-index elements */\n  }\n  .videoSidebar__button {\n    padding: 5px;\n    text-align: center;\n  }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
