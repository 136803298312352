import React, {useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Footer.css";
import useThemeStyle from "../../hook/useThemeStyle";

const Footer = () => {
	const {t, i18n: {changeLanguage, language}} = useTranslation()
	const settingStyle = useThemeStyle();

	useEffect(()=>{
	},[])


	const location = useLocation()
	const margin = (location.pathname === "/notify" || 
					location.pathname ===  "/my-list" || 
					location.pathname ===  "/downloads" || 
					location.pathname ===  "/terms-of-use" ||
					location.pathname ===  "/privacy-policy") ? "mt-0" : "mt-5";

	return (
		<footer className={`footer py-5 ${margin}`}>
			<div className="container">
				<div className="row">
					
					<div className="col-md-3 mb-4">
						<span className="translate-globe">
							<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1706407601/globe_1_xqvxzl.svg" alt="globe"/>
						</span>

						<select 
							className="form-select btn btn-secondary border-0" 
							aria-label="Language Selector" 
							onChange={(e)=> changeLanguage(e.target.value)} 
							value={language}
							style={{textAlign: "center", width:'185px' }}>

							<option value="en">{language === 'en'}English</option>
							<option value="xh">{language === 'xh'}Xhosa</option>
							<option value="np">{language === 'np'}Nigerian Pidgin</option>
							<option value="yb">{language === 'yb'}Yoruba</option>
							<option value="fr">{language === 'fr'}French</option>
							<option value="igb">{language === 'igb'}Igbo</option>
							<option value="sml">{language === 'sml'}Somali</option>
							<option value="twi">{language === 'twi'}Twi</option>
						</select>
					</div>

					<div className="col-md-2 mb-4 pl-md-3">
						<h5 className="mb-4 bg-none" style={settingStyle}>{t("navigation")}</h5>
						<ul>
							{/* <li className="mb-4 no-component">
								<Link to="#">{t("home")}</Link>
							</li> */}
							<li className="mb-4">
								<Link to="/pricing-plan" className="bg-none" style={settingStyle}>{t("pricingPlans")}</Link>
							</li>
							{/* <li className="mb-4 no-component">
								<Link to="#">{t("aboutUs")}</Link>
							</li>
							<li className="mb-2 no-component">
								<Link to="#">{t("helpCenter")}</Link>
							</li> */}
						</ul>
					</div>

					<div className="col-md-2 mb-4 pl-md-5">
						<h5 className="mb-4 bg-none" style={settingStyle}>{t("legal")}</h5>
						<ul>
							{/* <li className="mb-4 no-component">
								<Link to="#">{t("investorRelations")}</Link>
							</li>
							<li className="mb-4 no-component">
								<Link to="#">{t("jobs")}</Link>
							</li> */}
							<li className="mb-4">
								<a href="/privacy-policy" className="bg-none" style={settingStyle} >{t("privacyPolicy")}</a>
							</li>
							<li className="mb-4">
								<a href="/terms-of-use" className="bg-none" style={settingStyle} >{t("termsOfService")}</a>
							</li>
						</ul>
					</div>

					<div className="col-md-2 mb-4 pl-md-5">
						<h5 className="mb-2 bg-none" style={settingStyle}>{t("talkToUs")}</h5>
						<ul>
							<li className="mt-4 mb-2">
								<Link to="#" className="bg-none" style={settingStyle}>info@afrowatch.co</Link>
							</li>
							<li className="mb-2">
								<a 
									href="https://forms.gle/7mkTjgdXqgMAui6aA" 
									className="bg-none" 
									style={settingStyle}
									target="_blank"
									rel="noopener noreferrer"
								>
									Help
								</a>
							</li>
						</ul>
					</div>

					<div className="col-md-2 mb-4 pl-md-5">
						<h5 className="bg-none" style={settingStyle}>{t("followUs")}</h5>
						<ul className="d-flex mb-4">
							<li className="socials">
								<a href="https://www.instagram.com/afrowatch.co/" target="_blank">
									<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620740889/AfroWatch/Instagram_dhwuas.svg" alt="instagram-logo"/>
								</a>
							</li>
							<li className="socials">
								<a href="https://twitter.com/Afrowatch" target="_blank" >
									<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620740893/AfroWatch/Twitter_da6mxz.svg" alt="twitter-logo"/>
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div className="row">
					<div className="col text-center">
						<hr className="my-4" />
						<p className="copy-right bg-none"  style={settingStyle}>
							&#169; {new Date().getFullYear()} Afrowatch, inc. All Rights Reserved.
						</p>
					</div>
				</div>

			</div>
		</footer>
	);
};

export default Footer;
