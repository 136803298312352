// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: #2D132C;
}

label.p-FieldLabel {
    color: white !important;
}
.Label {
    color: #ffffff !important;
}
.p-Field{
    color: white !important;
}

#app-root {
    height: 100vh;
}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent !important;
    color: fieldtext !important;
}

/* For modern browsers */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

/* For Safari */

input:focus, input.form-control:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

/* Remove react-overlay-error in dev mode */
iframe#webpack-dev-server-client-overlay {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,2BAA2B;IAC3B,iCAAiC;IACjC,wCAAwC;IACxC,2BAA2B;AAC/B;;AAEA,wBAAwB;AACxB;IACI,4CAA4C;AAChD;;AAEA,eAAe;;AAEf;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,gBAAgB;IAChB,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA,2CAA2C;AAC3C;IACI,aAAa;AACjB","sourcesContent":["* {\n    font-family: 'Poppins', sans-serif;\n}\n\nbody {\n    background-color: #2D132C;\n}\n\nlabel.p-FieldLabel {\n    color: white !important;\n}\n.Label {\n    color: #ffffff !important;\n}\n.p-Field{\n    color: white !important;\n}\n\n#app-root {\n    height: 100vh;\n}\n\ninput:-internal-autofill-selected {\n    appearance: menulist-button;\n    background-image: none !important;\n    background-color: transparent !important;\n    color: fieldtext !important;\n}\n\n/* For modern browsers */\ninput:-webkit-autofill {\n    -webkit-box-shadow: 0 0 0 1000px white inset;\n}\n\n/* For Safari */\n\ninput:focus, input.form-control:focus {\n    outline:none !important;\n    outline-width: 0 !important;\n    box-shadow: none;\n    -moz-box-shadow: none;\n    -webkit-box-shadow: none;\n}\n\n/* Remove react-overlay-error in dev mode */\niframe#webpack-dev-server-client-overlay {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
