// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close_btn{
    background: none;
    outline: none;
    position: absolute;
    right: 20px;
    /* top: 10px; */
}

.navdrawer_afrowatch_logo{
    position: relative;
    left: 44px;
    top: 20px;
}

.genres{
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 80px;
    text-align: center;
    color: #FFFFFF!important;
    opacity: 0.5;
    list-style-type: none;
    overflow-y: scroll;
    height: 100vh;
}

hr{
    opacity: 0.1;
    border: 1px solid #FFFFFF;
}

`, "",{"version":3,"sources":["webpack://./src/components/Nav/NavDrawer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,wBAAwB;IACxB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".close_btn{\n    background: none;\n    outline: none;\n    position: absolute;\n    right: 20px;\n    /* top: 10px; */\n}\n\n.navdrawer_afrowatch_logo{\n    position: relative;\n    left: 44px;\n    top: 20px;\n}\n\n.genres{\n    font-style: normal;\n    font-weight: bold;\n    font-size: 25px;\n    line-height: 80px;\n    text-align: center;\n    color: #FFFFFF!important;\n    opacity: 0.5;\n    list-style-type: none;\n    overflow-y: scroll;\n    height: 100vh;\n}\n\nhr{\n    opacity: 0.1;\n    border: 1px solid #FFFFFF;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
