// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-snap-type: y mandatory;
}

.app {
  height: 100vh;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.app__videos {
  position: relative;
  height: 100%;
  border-radius: 10px;
  overflow-y: scroll;
  width: 100%;
  scroll-snap-type: y mandatory;
  margin-top: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app__videos::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app__videos {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}



@media(max-width: 480px){
  .app__videos {
      position: static;
      height: 100%;
      border-radius: 10px;
      overflow: scroll;
      width: 100%;
      scroll-snap-type: y mandatory;
      margin-top: -40rem;
  }
}

@media  (max-width:600px) {
  .app__videos {
    position: static;
    height: 100%;
    border-radius: 10px;
    overflow: scroll;
    width: 100%;
    scroll-snap-type: y mandatory;
    margin-top: -30rem;
}
  .app {
    height: 60vh;
    display: grid;
    place-items: center;
    cursor: pointer;
    z-index: 0;
    position: relative;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Shorts/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;;;;AAIA;EACE;MACI,gBAAgB;MAChB,YAAY;MACZ,mBAAmB;MACnB,gBAAgB;MAChB,WAAW;MACX,6BAA6B;MAC7B,kBAAkB;EACtB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,6BAA6B;IAC7B,kBAAkB;AACtB;EACE;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,UAAU;IACV,kBAAkB;EACpB;AACF","sourcesContent":["html {\n  scroll-snap-type: y mandatory;\n}\n\n.app {\n  height: 100vh;\n  display: grid;\n  place-items: center;\n  cursor: pointer;\n}\n\n.app__videos {\n  position: relative;\n  height: 100%;\n  border-radius: 10px;\n  overflow-y: scroll;\n  width: 100%;\n  scroll-snap-type: y mandatory;\n  margin-top: auto;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.app__videos::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.app__videos {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n\n\n@media(max-width: 480px){\n  .app__videos {\n      position: static;\n      height: 100%;\n      border-radius: 10px;\n      overflow: scroll;\n      width: 100%;\n      scroll-snap-type: y mandatory;\n      margin-top: -40rem;\n  }\n}\n\n@media  (max-width:600px) {\n  .app__videos {\n    position: static;\n    height: 100%;\n    border-radius: 10px;\n    overflow: scroll;\n    width: 100%;\n    scroll-snap-type: y mandatory;\n    margin-top: -30rem;\n}\n  .app {\n    height: 60vh;\n    display: grid;\n    place-items: center;\n    cursor: pointer;\n    z-index: 0;\n    position: relative;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
