// src/components/CountryCard/CountryCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { COUNTRY_FLAGS } from '../../constants/constants';
import './CountryCard.css';

const CountryCard = ({ country, onClick }) => {
	const isAfrime = country.name === 'Kids Afrime';
  
	const cardStyle = {
		background: isAfrime ? 
			'url(https://images.unsplash.com/photo-1547891654-e66ed7ebb968?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80)' : 
			'#2D132C',
		backgroundSize: 'cover',
		borderRadius: '8px',
		height: '72px'
	};

	return (
		<div className="col-12 col-md-3 mb-3">
			<Link 
				onClick={() => onClick(country.name)}
				to={country.link}
				style={{ textDecoration: 'none' }}
			>
				<div className="country-card-wrapper">
					<div className="card country-card" style={cardStyle}>
						<div className="card-body d-flex align-items-center p-3">
							<div className="d-flex align-items-center w-100">
								{country.isCountryCard && (
									<div className="flag-container">
										<img 
											src={COUNTRY_FLAGS[country.key]}
											alt={`${country.name} flag`}
											className="flag-image"
										/>
									</div>
								)}
								<span className="modal-text" style={{ color: 'white' }}>
									{country.name}
								</span>
							</div>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default CountryCard;