import React, {useState, useLayoutEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { INITIAL_COUNTRIES, STYLES } from '../../constants/constants';
import CountryCard from './CountryCard';
import {Link, useNavigate} from 'react-router-dom';
import SearchDropdown from './SearchDropdown';
import './ModalCountrySelection.css'

const ModalCountrySelection = ({ closed }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	const [region, setRegion] = useState('');
	const currentUser = localStorage.getItem("currentUser");
	const profileId = localStorage.getItem("selected_prof_id");
	const navigate = useNavigate()

	// Prevent scrolling while modal is open
	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		document.body.style.overflow = "hidden";
		return () => (document.body.style.overflow = originalStyle);
	}, []);


	// Set region to localStorage and update state
	const updateRegion = (newRegion) => {
		// Get existing region data from localStorage
		const regionData = JSON.parse(localStorage.getItem("region")) || {};
	
		// Update region for current profileId
		regionData[profileId] = newRegion;
	
		// Save the updated region data to localStorage
		localStorage.setItem('region', JSON.stringify(regionData));
		
		// Update the state with the new region
		setRegionState(newRegion);
		setRegionByProfile(regionData);
	};

	const displayedCountries = INITIAL_COUNTRIES.filter(country => country.isCountryCard);
	const searchableCountries = INITIAL_COUNTRIES.filter(country => !country.isCountryCard);

	const filteredCountries = searchableCountries.filter(country => 
		country.name.toLowerCase().includes(searchTerm.toLowerCase())
	);


	return (
		<div className="modal-wrapper">
			<div className="modal-backdrop fade show" onClick={closed}/>
			<div className="modal-container">
				<div className="modal-dialog">
					<div className="modal-content px-4 pb-5 pb-md-0">
						<div className="modal-body">
							<div className="row mt-5">
								<div className="col-md-12 d-flex justify-content-between align-items-center">
									<h5 className="modal-title">Choose your country</h5>
									<CloseIcon 
										className="modal-close-icon"
										onClick={closed}
									/>
								</div>
							</div>
							<div className="row mt-5 countries-grid">
								{displayedCountries.map(country => (
									<CountryCard 
										key={country.id}
										country={country}
										onClick={() => updateRegion(country.name)}
									/>
								))}
							</div>
							<div className="row mt-4">
								<SearchDropdown
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									showDropdown={showDropdown}
									setShowDropdown={setShowDropdown}
									countries={filteredCountries}
									onSelect={(countryName) => updateRegion(countryName)}
								/>
							</div>

							<div className="row mb-4">
								<div className="col-12">
									<button 
										type="button" 
										className="modal-done-button"
										onClick={closed}
									>
                    Done
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalCountrySelection;