import './SearchDropdown.css';

const SearchDropdown = ({ 
	searchTerm, 
	setSearchTerm, 
	showDropdown, 
	setShowDropdown, 
	countries, 
	onSelect 
}) => {
	const handleInputClick = (e) => {
		e.stopPropagation();
	};

	const handleSelect = (countryName) => {
		onSelect(countryName);
		setShowDropdown(false);
		setSearchTerm(countryName);
	};

	const handleInputChange = (e) => {
		setSearchTerm(e.target.value);
		setShowDropdown(true); 
	};

	return (
		<div className="col-12">
			<p className="text-white mb-3">Want to select other countries?</p>
			<div className="search-select-container">
				<div className="search-select-box" onClick={() => setShowDropdown(!showDropdown)}>
					<div className="search-input-wrapper">
						<input
							type="text"
							placeholder="Search & select"
							value={searchTerm}
							onChange={handleInputChange} // Updated here
							onClick={handleInputClick}
							className="search-input"
						/>
						<span className="dropdown-arrow">▼</span>
					</div>
				</div>
        
				{showDropdown && (
					<div className="search-dropdown">
						{countries.filter(country => 
							country.name.toLowerCase().includes(searchTerm.toLowerCase())
						)
							.map(country => (
								<div 
									key={country.id}
									className="search-dropdown-item"
									onClick={() => handleSelect(country.name)}
								>
									{`${country.name} (Coming soon)`}
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchDropdown;
