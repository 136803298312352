import { v4 as uuidv4 } from 'uuid';

export const COUNTRY_FLAGS = {
	'Ghana': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656399/gh_hjae32.svg',
	'Burkina Faso': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656631/bf_gt7y1i.svg',
	'Morocco': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656690/ma_l8gl2y.svg',
	'Liberia': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656742/lr_tjb2uc.svg',
	'South Africa': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656473/za_omyhe4.svg',
	'Djibouti': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732657263/dj_gzxrvs.svg',
	'Angola': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732657300/ao_bonwxq.svg',
	'Egypt': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732657351/eg_qhmrqi.svg',
	'Botswana': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732657386/bw_lrordw.svg',
	'Nigeria': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656329/ng_wdbzxa.svg',
	'Chad': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732657435/td_l61spj.svg',
	'Uganda': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732657470/ug_h5soe0.svg',
	'Tanzania': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656538/tz_bxjskw.svg',
	'Kenya': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1732656593/ke_p4cbz0.svg',
	'Black': 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1741320592/Flag_of_the_UNIA_uqnuqx.svg'
};

export const RELEASE_TYPES = {
	COMING_SOON: 'coming soon',
	RELEASED: 'released'
};

export const INITIAL_COUNTRIES = [
	{
		id: uuidv4(),
		key: 'Nigeria',
		name:'Nigeria',
		released: RELEASE_TYPES.COMING_SOON,
		isCountryCard: true,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Ghana',
		name: 'Ghana',
		released: RELEASE_TYPES.COMING_SOON,
		isCountryCard: true,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'South Africa',
		name: 'South Africa',
		released: RELEASE_TYPES.COMING_SOON,
		isCountryCard: true,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Tanzania',
		name: 'Tanzania',
		released: RELEASE_TYPES.COMING_SOON,
		isCountryCard: true,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Kenya',
		name: 'Kenya',
		released: RELEASE_TYPES.COMING_SOON,
		isCountryCard: true,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Kids Afrime',
		name: 'Kids Afrime',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '/profile/{profileId}/watch/Afrime',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Egypt',
		name: 'Egypt',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Morocco',
		name: 'Morocco',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Botswana',
		name: 'Botswana',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Liberia',
		name: 'Liberia',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Djibouti',
		name: 'Djibouti',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Angola',
		name: 'Angola',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Chad',
		name: 'Chad',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Uganda',
		name: 'Uganda',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Burkina Faso',
		name: 'Burkina Faso',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: false,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
	{
		id: uuidv4(),
		key: 'Black',
		name: 'All Black',
		released: RELEASE_TYPES.RELEASED,
		isCountryCard: true,
		link: '#',
		prompt: { message: 'Coming soon', isShown: false }
	},
];
