import React, { useState, useEffect, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { Link, useLocation} from "react-router-dom";
import { navigate } from "wouter/use-location";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import $ from "jquery";
import axios from "axios";
import TopNavBar from "../Nav/TopNavBarV2";
import NavDrawer from "../Nav/NavDrawer";
import MediaPlayer from "../MediaPlayer/MediaPlayerPage";
import ContentSliderTrending from "../Slider/ContentSliderTrending";
import ContentSliderRecentlyAdded from "../Slider/ContentSliderRecentlyAdded";
import ContentSliderWatchingNow from "../Slider/ContentSliderWatchingNow";
import ContentSliderMostWatched from "../Slider/ContentSliderMostWatched";
import FeaturedMediaPlayer from "../MediaPlayer/FeaturedMediaPlayer"
import Footer from "../Footer/Footer";
import BottomNav from "../Nav/BottomNav";
import "./MoviesPage.css";
import useThemeStyle from "../../hook/useThemeStyle";

const MoviesPage = () => {
	const location = useLocation();
	const { closeModal } = useContext(ModalContext);
	const { t } = useTranslation();
	const settingStyle = useThemeStyle();

	const [isNavNotShowing, showNav] = useState(true);
	const [isModal, setModalDisplay] = useState(false);
	const [isNavBarSearch, setNavBarSearch] = useState({ active: true });
	const [isAlert, setAlert] = useState(false);
	const [message, setMsg] = useState("");
	const [isFooter, setFooter] = useState({ footerActive: true, bottomNavActive: false,});
	const [content, setTrending] = useState([]);
	const [recentlyAdded, setRecentlyAdded] = useState([]);
	const [watchingNow, setWatchingNow] = useState([]);
	const [mostWatched, setMostWatched] = useState([]);
	const [featured, setFeatured] = useState({});

	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const currentUser = localStorage.getItem("currentUser");
	const profileName = localStorage.getItem("profile_name");
	const profileIcon = localStorage.getItem("profile_icon");

	const profileId = localStorage.getItem("selected_prof_id");
	const regionObj = localStorage.getItem("region") ? JSON.parse(localStorage.getItem("region")): {};
	const region = regionObj[profileId];

	//const featuredStreamId = localStorage.getItem("featured_stream_id");
	const [featuredStreamId, setFeaturedStreamId] = useState(localStorage.getItem("featured_stream_id"));

	const profile_id = location.state?.profile_id;

	useEffect(()=>{
		const getUser = async()=>{
			try {
				const res = await axios_.get(`/user/${currentUser}`,  { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }})
				const {data} =  res.data;
				if(res.data.success){
					return data
				}else{
					console.log(res.data.message)
					return null;
				}
			} catch (error) {
				console.error('An error occurred while fetching user data:', error);
				return null;
			}
		}

		const checkUserPlanExists = async () => {
			const user = await getUser();
			if (user) {
				if (user.currentPlan) {
					return;
				} else {
					//cleared when the browser tab or window is closed
					sessionStorage.setItem('redirectMsg', 'You need a plan to view content 😊')
					//
					navigate('/pricing-plan', { 
						state: { message: 'You need a plan to view content 😊' }, 
					})
					window.location.reload()
				}
			}
		};

		checkUserPlanExists()
	},[navigate])

	useEffect(() => {
		if (region) {
			getTrendingContent();
			getRecentlyAddedContent();
			getWatchingNow();
			getContentMostWatched();
			setModalDisplay(false);
		} else {
			setModalDisplay(true);
		}

		localStorage.setItem("profile_id", profile_id);

		//Determine Mobile or not
		if ($(window).width() <= 600) {
			setNavBarSearch({ ...isNavBarSearch, active: false });
			setFooter({
				...isFooter,
				footerActive: false,
				bottomNavActive: true,
			});
		} else {
			setNavBarSearch({ ...isNavBarSearch, active: true });
			setFooter({
				...isFooter,
				footerActive: true,
				bottomNavActive: false,
			});
		}
	}, []);

	const showNavigation = () => {
		showNav(!isNavNotShowing);
		setModalDisplay(false);
	};

	const toggleModal = () => { isModal ? setModalDisplay(false) : setModalDisplay(true);};

	const getTrendingContent = async () => {
		if (region) {
			const res = await axios_.get(`/content/${region}/trending`);
			if (res.data.status === 200) {
				setTrending(res.data.content);
			} else {
				console.log(res.data.message);
			}
		}
	};

	const getRecentlyAddedContent = async () => {
		if (region) {
			const res = await axios_.get(`/content/${region}/recently-added`);
			if (res.data.status === 200) {
				setRecentlyAdded(res.data.content);
			} else {
				console.log(res.data.message);
			}
		}
	};

	const getContentMostWatched = async () => {
		if (region) {
			const res = await axios_.get(`/content/${region}/most-watched`);
			if (res.data.status === 200) {
				setMostWatched(res.data.content);
			} else {
				console.log(res.data.message);
			}
		}
	};

	//On region state change
	useEffect(() => {
		getTrendingContent();
		getWatchingNow();
		getRecentlyAddedContent();
		getContentMostWatched();
		// navigate(window.location.href);
	}, [region]);

	//update featured stream id state using localStorage
	useEffect(() => {
		// Sync with localStorage changes
		const syncWithLocalStorage = () => {
			const newId = localStorage.getItem("featured_stream_id");
			if (newId !== featuredStreamId) {
				setFeaturedStreamId(newId); // Update state
			}
		};
		// Listen for `storage` changes
		window.addEventListener("storage", syncWithLocalStorage);
	
		// Initial manual sync (in case `storage` event doesn't fire in the same tab)
		const interval = setInterval(syncWithLocalStorage, 100);
		
		return () => {
			window.removeEventListener("storage", syncWithLocalStorage);
			clearInterval(interval); // Clean up
		};
	}, [featuredStreamId]);

	useEffect(() => {
		getTrendingContent();
		for (const trendingItem of content) {
			if (trendingItem.content_id === featuredStreamId) {
				setFeatured(trendingItem);
			}
		}
		if(featured && Object.keys(featured).length > 0){
			//console.log(featured)
		}
	}, [region, featuredStreamId]); //on featuredStream Id change every 2 minutes, set new featured content

	const getWatchingNow = async () => {
		if (region) {
			try {
				const res = await axios_.get(`/content/${region}/${currentUser}/${localStorage.getItem("selected_prof_id")}/watching-now`);
				if (res.data.status === 200 && res.data.content !== null) {
					setWatchingNow(res.data.content);
				} else {
					console.log(res.data.message);
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	const addToList = async (e) => {
		e.preventDefault();
		try {
			//should be the content_id with attribute isTrending or isDisplay
			const res = await axios_.post(`/${currentUser}/user/${profile_id}/my-list/${featuredStreamId}`);

			if (res.data.success) {
				setMsg(res.data.message);
				setAlert(true);
			} else {
				console.log(res.data.message);
				setMsg(res.data.message);
				setAlert(true);
			}
		} catch (e) {
			setAlert(false);
			console.log(e);
		}
	};

	return (
		<div className="bg-moviesPage" onClick={() => closeModal()} >
			{/* {isNavBarSearch.active ? <TopNavBar profile={profileName} profileIcon={profileIcon} region={region} navToggle={showNavigation}></TopNavBar> : <TopNavBar profile={profileName} profileIcon={profileIcon} region={region} navToggle={showNavigation}></TopNavBar> }
             */}
			<TopNavBar profile={profileName} profileIcon={profileIcon} region={region} navToggle={showNavigation}></TopNavBar>
			<span className="nav_drawer">
				{isNavNotShowing ? null : (<NavDrawer closeDrawer={showNavigation} />)}
			</span>

			{/* {isModal ? <ModalCountrySelection closed={toggleModal} /> : null} */}

			{/* Auto play video- trailer of movie */}
			{isFooter.footerActive ? (
				<div>
					{isNavNotShowing ? (<FeaturedMediaPlayer />) : (<FeaturedMediaPlayer navDrawer={"video-container-nav-drawer-showing"}/>)}
					<div className="movie_description ml-3 ml-md-5">
						{(() => {
							const description = featured ? featured.content_description : "";
							const splitDescription = description != undefined ? description.match(/.{1,50}/g) || [] : ""; //maximum length of each line 50 chars each
							return splitDescription.length > 0
								? splitDescription.map((sentence, index) => (
									<p key={index}>
										{t("description", {description: sentence,})}
									</p>
								))
								: null;
						})()}
					</div>

					<div className="mb-4" style={{position: 'relative', bottom: '100px'}}>
						{/* should be the content with attribute isTrending or isDisplay set to true featured here */}
						<Link className="watch-btn py-2 px-5" to={`/watch/${featuredStreamId}/f/play`}>
							<PlayArrowRoundedIcon className="play-icon"/>
							{t("watch")}
						</Link>
						<Link className="add-to-list-btn py-2 px-5" to="#" onClick={addToList}>
							<AddRoundedIcon className="add-icon"/>
							{t("addToList")}
						</Link>
					</div>
				</div>
			) 
				: 
				(
					<div>
						{
							content.length > 0 ? 
								(
									// <div>
									// 	<img
									// 		className="featured_content_img"
									// 		src={featured.video_image_url ? featured.video_image_url : "https://res.cloudinary.com/www-softroniiks-com/image/upload/v1697176380/default_content_image_waw6bf.png"}
									// 		alt="display_content_img"
									// 	/>
									// </div>
									<>
										{isNavNotShowing ? (<FeaturedMediaPlayer />) : (<FeaturedMediaPlayer navDrawer={"video-container-nav-drawer-showing"}/>)}
										<p style={{position: 'absolute',top: '130px',left: '17px'}} className="movie-title">{featured.content_name}</p>
										<div className="movie_description ml-3 ml-md-5">
											{featured.content_description && featured.content_description.length > 110 ? `${featured.content_description.substring(0,110)}...` : featured.content_description}
										</div>
										<div className="action-btn">
											<Link className="watch-btn py-1 px-3" to={`/watch/${featuredStreamId}/play`}>
												<PlayArrowRoundedIcon className="play-icon"/>
												{t("watch")}
											</Link>
											<Link className="add-to-list-btn py-1 px-4" to="#" onClick={addToList}>
												<AddRoundedIcon className="add-icon"/>
												{t("addToList")}
											</Link>
										</div>
									</>
								) 
								: 
								(
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<div className="text-center py-5 text-white">
													No trending content yet in this region
												</div>
											</div>
										</div>
									</div>
								)
						}
					</div>
				)
			}

			{
				watchingNow.length === 0 && content.length === 0 && mostWatched.length === 0 && recentlyAdded.length === 0 ?
				
					<div className="container-fluid">
						<div className="row justify-content-center py-5">
							<h4 className="text-white text-center">No content yet available in this region!</h4>
						</div>
					</div> 
					:
					<div className="container-fluid">
						{/* Watching Now */}
						{
							watchingNow && watchingNow.length > 0 &&
						<div className="row py-md-5 watchingNow">
							<div className="col-md-12 mt-4">
								<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3 bg-none" style={settingStyle}>
									{t("watchingNow")}
								</h1>
							</div>

							{watchingNow && watchingNow.length > 0 ? (<ContentSliderWatchingNow watchingNow={watchingNow} />) : 
								(
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<div className="text-center text-white">
													You arent watching anything currently
												</div>
											</div>
										</div>
									</div>
								)
							}
						</div>
						}

						{/* Content Trending */}
						{
							content.length > 0 && 
							<div className="row Trending">
								<div className="col-md-12 mt-4">
									<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3 bg-none" style={settingStyle}>
										{t("trending")}
									</h1>
								</div>

								{content.length > 0 ? <ContentSliderTrending trendingMovies={content}/> :  <p className="mb-md-4 mt-md-2 ml-md-5"></p>}
							</div>
						}

						{/* Content Most Watched */}
						{
							mostWatched.length > 0 &&
							<div className="row mostWatched">
								<div className="col-md-12 mt-4">
									<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3 bg-none" style={settingStyle}>
										{t("mostWatched")}
									</h1>
								</div>
								{mostWatched.length > 0 ? (<ContentSliderMostWatched mostWatched={mostWatched} />) : (
									<p className="mb-md-4 mt-md-2 ml-md-5"></p>
								)}
							</div>
						}
					
						{/* Content Recently added */}
						<div className="row recentlyAdded pb-5">
							<div className="col-md-12">
								<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3 bg-none" style={settingStyle}>
									{t("recentlyAdded")}
								</h1>
							</div>

							{recentlyAdded.length > 0 ? <ContentSliderRecentlyAdded recentlyAdded={recentlyAdded}/> :  <p className="mb-md-4 mt-md-2 ml-md-5"></p>}
						</div>
					

						<div className="row drama" data-list-context="genre">
							<div className="col-md-12"></div>
						</div>

						<div className="row action&amp;adventure" data-list-context="genre">
							<div className="col-md-12"></div>
						</div>
					</div>
			}

			{ isFooter.footerActive ? (<Footer />) : ( <BottomNav className="d-block d-md-none d-sm-block" /> )}
		</div>
	);
};

export default MoviesPage;