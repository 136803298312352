// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Force the label color to be white with !important */
.stripe-element .Label {
    color: #ffffff !important;
    font-weight: bold !important;
  }

  .Label{
    
  }

label.p-FieldLabel {
    color: white !important;
}
.Label {
    color: #ffffff !important;
}
.p-Field{
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Pricing/element.css"],"names":[],"mappings":"AAAA,sDAAsD;AACtD;IACI,yBAAyB;IACzB,4BAA4B;EAC9B;;EAEA;;EAEA;;AAEF;IACI,uBAAuB;AAC3B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,uBAAuB;AAC3B","sourcesContent":["/* Force the label color to be white with !important */\n.stripe-element .Label {\n    color: #ffffff !important;\n    font-weight: bold !important;\n  }\n\n  .Label{\n    \n  }\n\nlabel.p-FieldLabel {\n    color: white !important;\n}\n.Label {\n    color: #ffffff !important;\n}\n.p-Field{\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
