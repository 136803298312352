import React, { useState, useEffect } from "react";
import axios from "axios";
import { axiosInstance } from "../../apiClient";
import { useNavigate, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { convertUSDToNGN } from "../../utils/currency";

import ExplorePlans from "./ExplorePlans";
import "./CurrentPlan.css";
import { SUBSCRIPTION_INCOMPLETE } from "../constants";

const CurrentPlan = () => {
	const [plan, setPlan] = useState({});
	const [plans, setPlans] = useState([]);
	const [user, setUser] = useState({});
	const [subscription, setSub] = useState();
	const [explore, setExplore] = useState(false);
	const [changePlan, setChangePlan] = useState(false);
	const [cards, setCards] = useState({});
	const [cancelMessage, setMessage] = useState("");
	const [oneClickOpen, setOneClickOpen] = useState(false);
	const [snackOpen, setSnackOpen] = useState(false);
	const [newPrice, setNewPrice] = useState(null);

	const currentUser = localStorage.getItem("currentUser");
	const currentProfile = localStorage.getItem("profile_name");
	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const navigate = useNavigate();

	const getUser = async () => {
		const resp = await axios_.get(`/user/${currentUser}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
		const { data } = resp.data;
		if (resp.data.success) {
			return data;
		} else {
			console.log(resp.data.message);
		}
	};

	const getPlan = async () => {
		const user = await getUser();
		setUser(user);
		if (user.currentPlan) {
			const res = await axios_.get(`/plan/${user.currentPlan["id"]}`);
			if (res.status === 200) {
				setPlan(res.data.plan);
			} else {
				console.log("The plan does not exists");
			}

			const resp_two = await axios_.get(
				`/user/stripe/${currentUser}/${user.currentPlan.subscriptionId}`
			);
			if (resp_two.data.status === 200) {
				setSub(resp_two.data.sub);
			} else {
				console.log(resp_two.data.message);
			}
		} else {
			setPlan({
				subscription_name: "no plan",
				price: 0,
			});
		}
	};

	const getUserCards = async () => {
		try {
			if (user.stripeCustomerId) {
				const res = await axios_.get(
					`/${user.stripeCustomerId}/stripe/cards`
				);
				if (res.status === 200) {
					setCards(res.data);
				} else {
					console.log("didnt get cards");
				}
			}
		} catch (e) {
			console.log("The stripe customer might not exists or is wrong");
		}
	};

	useEffect(() => {
		getUser();
		getPlan();
	}, []);

	useEffect(() => {
		getUserCards();
	}, [user]);

	const toggleExplore = () => {
		if (explore || changePlan) {
			setExplore(false);
			setChangePlan(false);
		} else {
			setExplore(true);
			setChangePlan(true);
		}
	};

	const handleClickOpen = () => {
		setOneClickOpen(true);
	};

	const handleClose = () => {
		setOneClickOpen(false);
	};

	/**Cancel Subscription */
	const cancelSub = async (e) => {
		try {
			const res = await axios_.post(
				`/cancel-subscription/${currentUser}`,
				{
					currentPlan: {
						subscriptionId: user.currentPlan.subscriptionId,
					},
				}
			);
			if (res.data.status === 200 && res.data.periodEnd === Date.now()) {
				setMessage(res.data.message);
				setSub(res.data.sub);
				setSnackOpen(true);
				setTimeout(() => {
					localStorage.removeItem("currentUser");
					navigate("/login");
				}, 3000);
			} else {
				setMessage(res.data.message);
				setSub(res.data.sub);
				setSnackOpen(true);
			}
		} catch (err) {
			console.log(err);
			setMessage("Error. no plan yet");
			setSnackOpen(true);
		}
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleSnackBarClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const changePlans = () => {
		setChangePlan(true);
	};

	const convertAmount = async (price) => {
		try {
			const ngnPrice = await convertUSDToNGN(price); // Convert $100 to NGN
			setNewPrice(ngnPrice);
			return ngnPrice;
		} catch (error) {
			console.error(error.message);
		}
	};
	useEffect(() => {
		convertAmount(plan.price);
	},[plan, newPrice]);

	

	const getPlans = async () => {
		const res = await axiosInstance.get("/plans");
		if (res.status === 200) {
			setPlans(res.data);
		}
	};

	useEffect(() => {
		getPlans();
	}, []);


	return (
		<>
			<>
				{explore || changePlan ? (
					<ExplorePlans
						changePlan={changePlan}
						toggleExplore={toggleExplore}
						userId={user._id}
						user={user}
						current={user.currentPlan}
						currentPlan={plan.subscription_tier}
					/>
				) : (
					<div
						style={{
							border: "1px solid #C92941",
							width: "100%",
							borderRadius: "10px",
							background: "#1F0F25",
							height: "100%",
						}}
						className="card mt-5 pb-3 pb-md-0"
					>
						<div className="col-md-12">
							<>
								<div className="d-flex  mt-3">
									<div>
										<h4 className="text-white">
                                            Subscription
										</h4>
									</div>
								</div>

								{plan.subscription_name !== "no plan" && (
									<p className="mt-3 sub-labels">
                                        Transaction ID-{" "}
										{user && user.currentPlan
											? user.currentPlan.subscriptionId.split(
												"_"
											)[1]
											: null}{" "}
									</p>
								)}

								{plan.subscription_name !== "no plan" && (
									<div className="d-block d-md-flex justify-content-between ">
										<div>
											<p className="mt-3 sub-labels">
                                                Current plan
											</p>
											<Link
												to="/pricing-plan"
												target="_blank"
												style={{
													fontWeight: "bold",
													fontSize: "14px",
													lineHeight: "5px",
													color: "#C92941",
												}}
											>{`${plan && plan.subscription_tier ? plan.subscription_tier : "no plan"} for
												${plan && plan.price && newPrice ? (plans.ipInformation && plans.ipInformation.country === 'Nigeria' ?  `NGN ${newPrice}` : `$${plan.price}`) : 0 } / month`}</Link>
											<p
												className="mt-2"
												style={{
													fontWeight: "bold",
													fontSize: "14px",
													lineHeight: "5px",
													color: "#C92941",
												}}
											>
                                                Status:{" "}
												{user &&
                                                user.currentPlan &&
                                                user.currentPlan.status !== null
													? user.currentPlan.status
													: "Not Active"}
											</p>
										</div>

										<div
											className="d-block d-md-flex align-items-center"
										>
											<div className="mr-5">
												<p className="sub-labels mt-3">
                                                    Start date:
												</p>
												<p
													style={{
														color: "#FFFFFF",
														fontSize: "14px",
														lineHeight: "5px",
													}}
												>
													{subscription
														? new Date(
															subscription.current_period_start *
                                                                  1000
														).toDateString()
														: "No subscription"}
												</p>
											</div>
											<div>
												<p className="sub-labels mt-3">
                                                    End date:
												</p>
												<p
													style={{
														color: "#FFFFFF",
														fontSize: "14px",
														lineHeight: "5px",
													}}
												>
													{subscription
														? new Date(
															subscription.current_period_end *
                                                                  1000
														).toDateString()
														: "No subscription"}
												</p>
											</div>
										</div>
									</div>
								)}

								{user &&
                                user.profiles &&
                                user.profiles.length > 0 ? (
										user.profiles[0].profile_name ===
                                    currentProfile ? (
												<div className="sub-btn pb-2 pb-md-5">
													{!user.currentPlan ? (
														<button
															className="explore-btn d-block py-2 px-3  mt-5"
															onClick={toggleExplore}
														>
                                                    Explore Plans
														</button>
													) : (
														<>
															<button
																className="change-btn d-block py-2 px-4 mt-5 mr-0 mr-md-3"
																onClick={changePlans}
															>
                                                        Change Plans
															</button>
															{user.currentPlan.status ===
                                                    "active" ? (
																	<button
																		onClick={cancelSub}
																		className="cancel-sub-btn py-2 px-3 mt-5"
																		style={{
																			height: "fit-content",
																		}}
																	>
                                                            Cancel Subscription
																	</button>
																) : null}
														</>
													)}
												</div>
											) : null
									) : null}

								{user &&
                                user.profiles &&
                                user.profiles.length > 0 ? (
										user.profiles[0].profile_name ===
                                        currentProfile &&
                                    user.currentPlan &&
                                    user.currentPlan.status !==
                                        SUBSCRIPTION_INCOMPLETE ? (
												<div className="mt-20 d-block d-md-flex justify-content-end pb-5">
													{user.currentPlan.status !==
                                            null ? (
															user.currentPlan
																.cancelAtPeriodEnd ? (
																	<p
																		style={{
																			fontWeight: "bold",
																			fontSize: "14px",
																			lineHeight: "5px",
																			color: "#C92941",
																		}}
																	>
                                                        Ending on
																		{subscription
																			? new Date(
																				subscription.current_period_end *
                                                                      1000
																			).toDateString()
																			: null}
																	</p>
																) : null
														) : null}
												</div>
											) : null
									) : null}
							</>

							{/* One-click to cancel implementation */}
							{/* !oneClickOpen ?
									(
										<button onClick={handleClickOpen} className='cancel-sub-btn py-2 px-3'>
											Cancel Subscription
										</button>
									) :
									(
										<button onClick={cancelSub} className='cancel-sub-btn py-2 px-3'>
											One-click-to-cancel
										</button>

									) */}

							{snackOpen ? (
								<Snackbar
									open={snackOpen}
									autoHideDuration={8000}
									onClose={handleSnackBarClose}
									message={cancelMessage}
									action={action}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
								>
									<Alert
										style={{ marginBottom: "1rem" }}
										icon={
											<CheckCircleOutlineIcon fontSize="inherit" />
										}
										sx={{ width: "100%" }}
										severity="success"
										onClose={handleSnackBarClose}
									>
										{cancelMessage}
									</Alert>
								</Snackbar>
							) : null}
						</div>
					</div>
				)}
			</>
		</>
	);
};

export default CurrentPlan;
