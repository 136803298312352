import React, {useEffect} from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { List, ListItem, ListItemText } from "@mui/material";
import Box from '@mui/material/Box';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from "react-router";
import { convertLengthSecondsToTime } from "../../utils/utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2), 
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
	'& .MuiDialog-paper': {
		width: '80%',
		maxWidth: '1200px',
	},
}));

const VideoThumbnail = styled(Box)(({ theme }) => ({
	width: 120,
	height: 68,
	borderRadius: theme.shape.borderRadius,
	marginRight: theme.spacing(2),
	position: 'relative',
	backgroundColor: 'rgba(0, 0, 0, 0.08)',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden',
	'&:hover': {
		cursor: 'pointer',
		'& .playIcon': {
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
		}
	}
}));
  
const PlayIcon = styled(Box)(({ theme }) => ({
	position: 'absolute',
	backgroundColor: 'rgba(0, 0, 0, 0.5)',
	borderRadius: '50%',
	width: 36,
	height: 36,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	transition: 'background-color 0.2s',
}));
  
const SeasonDialogue=({ showSeason, seasons, onClose, contentName, contentId, seasonNumber, episodes })=> {
	const navigate = useNavigate()

	const handleClose = () => {
		// Call the parent's callback function(handleCloseDialogue) to update its state
		onClose();
	};

	useEffect(()=>{
		console.log(seasons)
	},[])

	const handlePlayVideo=(id)=>{
		navigate(`/watch/${id}/play`, {
			state: {
				contentId: id,
			},
		})
	}
  
	return (
		<React.Fragment>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={showSeason}
				maxWidth="lg"
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					{`${contentName}: Season ${seasonNumber}`}
				</DialogTitle>

				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={(theme) => ({
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					})}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers>
					<List>
						{episodes && episodes.length > 0 ? (
							episodes.map((episode, index) => (
								<React.Fragment key={index}>
									<ListItem onClick={()=>handlePlayVideo(contentId)} alignItems="flex-start" className="episode-list-item">
										<VideoThumbnail 
											onClick={() => handlePlayVideo(contentId || index)}
											sx={{
												backgroundImage: episode.episode_thumbnail ? `url(${process.env.REACT_APP_INPUT_BUCKET_IMAGES_CF}/${episode.episode_thumbnail})` : 'none',
												backgroundSize: 'cover',
												backgroundPosition: 'center',
											}}
										>
											<PlayIcon className="playIcon">
												<PlayArrowIcon sx={{ color: 'white' }} />
											</PlayIcon>
										</VideoThumbnail>
										<Box sx={{ flex: 1 }}>
											<ListItemText
												primary={`Episode ${index + 1}: ${episode.episode_name || 'Untitled'}`}
												secondary={
													<React.Fragment>
														{`${episode.episode_description || 'No description available.'}`}
														<Typography
															sx={{ display: 'inline' }}
															component="span"
															variant="body2"
															color="text.primary"
														>
															{convertLengthSecondsToTime(episode.length).hours > 0 || convertLengthSecondsToTime(episode.length).minutes > 0
																? `(${convertLengthSecondsToTime(episode.length).hours}h ${convertLengthSecondsToTime(episode.length).minutes
																}m)`
																: `${Math.round(episode.length)}s` || ''}
															{/* {`(${episode.length}) || ''}`} */}
														</Typography>
													</React.Fragment>
												}
											/>
										</Box>
									</ListItem>
									{index < episodes.length - 1 && <Divider sx={{background:'#2D2640',width: '100%'}} />}
								</React.Fragment>
							))
						) : (
							<ListItem>
								<ListItemText primary="No episodes available for this season." />
							</ListItem>
						)}
					</List>
				</DialogContent>
			</BootstrapDialog>
		</React.Fragment>
	);
}

export default SeasonDialogue;