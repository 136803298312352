import React, {useState, useEffect} from 'react';
import {Link, useSearchParams, createSearchParams, useNavigate} from 'react-router-dom';
import constants from '../constants';
import './NavDrawer.css'

const NavDrawer =(props)=>{
	const[linkInitial,setActive] = useState('#FFFFFF')
	const [genre, setGenre]= useState('')
	const [close, setClose] = useState(true)
	const navigate = useNavigate()

	const currentUser = localStorage.getItem("currentUser");

	const params = { genre: genre }
	useEffect(()=>{

	},[])

	const getContentByFilter=(e)=>{
		e.preventDefault()

		navigate({
			pathname: `/profile/${currentUser}/watch/genres`,
			search: `?${createSearchParams(params)}`,
			state:{}
		});
	}


	return(
		<div style={{ overflow: 'hidden'}}>
			<div className="row">
				<div style={{backgroundColor:'#2D132C'}} className="col-md-12">
                    
					<button onClick={props.closeDrawer} className="close_btn border-0"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619878217/AfroWatch/Vector_uxcqnq.svg" alt=""/></button>
					<img className="navdrawer_afrowatch_logo" style={{width:'190px',height:'188px'}} src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt="afrowatch_logo"/>
                    
					<ul className="mt-md-4 genres pr-md-5">
						{constants.GENRES.map((genre_)=>{
							return <span key={genre_}>
								<li><Link onMouseOver={(e)=> setGenre(genre_)} onClick={getContentByFilter} style={{color: linkInitial}} to="#">{genre_}</Link></li>
								<hr/>
							</span>
						})}
					</ul>
				</div>
			</div>
		</div>
	)

}

export default NavDrawer;