import React, { useState, useEffect } from "react";
import MovieCard from "../MoviesPage/MovieCardComponent";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CustomPrevArrow } from "../Svg/PrevArrow";
import { CustomNextArrow } from "../Svg/NextArrow";

const MovieSlider = (props) => {
	const [similarMovies, setSimilarMovies] = useState([]);
	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

	const contentNameStyle = {
		// bottom:'40px',
		// right: window.innerWidth < 600 ? '0px' : '10px',
		// position:'relative',
		fontSize: window.innerWidth < 600 ? "16px" : "18px",
	};

	//Similar Movies
	const getSimilarMovies = async () => {
		const requestConfig = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};

		const resp = await axios_.get(
			`/content/collection/${props.actors[0]}`,
			requestConfig
		);

		if (resp.data.status === 200) {
			setSimilarMovies(resp.data.content);
		} else {
			console.log(resp.data.message);
		}
	};

	useEffect(() => {
		getSimilarMovies();
	}, []);

	const settings = {
		dots: true,
		speed: 500,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		swipeToSlide: true,
		autoplaySpeed: 2000,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
				},
			},
		],
	};

	return (
		<div className="content">
			<div className="custom-container">
				{similarMovies.length > 1 ? (
					<Slider {...settings}>
						{similarMovies.map((content, pos) => (
							<div key={pos}>
								<div className="movie-show">
									<MovieCard
										image={
											content.video_image_url !== undefined
												? content.video_image_url
												: "https://res.cloudinary.com/www-softroniiks-com/image/upload/v1697176380/default_content_image_waw6bf.png"
										}
										content_id={content.content_id}
										actors={content.actors}
										content_name={content.content_name}
										date_uploaded={content.date_uploaded}
										genre={content.genre}
										video_path={content.video_path}
										isSeries={content.isSeries}
										seasons={content.seasons}
										length={content.length}
										description={content.content_description? content.content_description: ""}
										header_image={
											content.video_header_image_url
												? content.video_header_image_url
												: "https://res.cloudinary.com/www-softroniiks-com/image/upload/v1708159982/default_content_header_uvxcnu.png"
										}
									/>
									{/* Content name-- once > 14 chars truncate */}
								</div>
								{/* <span className='text-desc' style={contentNameStyle}>
									{
										window.innerWidth < 600 ?
											(content.content_name.length < 15 ? content.content_name : content.content_name.substring(0,14)) :
											content.content_name
									}
								</span>   */}
							</div>
						))}
					</Slider>
				) : similarMovies.length === 1 ? (
					<div className="movie-show">
						<MovieCard
							key={similarMovies[0]}
							image={
								similarMovies[0].video_image_url
									? similarMovies[0].video_image_url
									: "https://d2fft6uz2yutyc.cloudfront.net/default_video_image.png"
							}
							content_id={similarMovies[0].content_id}
							actors={similarMovies[0].actors}
							content_name={similarMovies[0].content_name}
							date_uploaded={similarMovies[0].date_uploaded}
							genre={similarMovies[0].genre}
							video_path={similarMovies[0].video_path}
							length={similarMovies[0].length}
							description={
								similarMovies[0].content_description
									? similarMovies[0].content_description
									: ""
							}
							header_image={
								similarMovies[0].video_header_image_url
									? similarMovies[0].video_header_image_url
									: "https://res.cloudinary.com/www-softroniiks-com/image/upload/v1708159982/default_content_header_uvxcnu.png"
							}
						/>

						{/* Content name-- once > 14 chars truncate */}
						{/* <span className="" style={contentNameStyle}>
							{window.innerWidth < 600
								? similarMovies[0].content_name.length < 15
									? similarMovies[0].content_name
									: similarMovies[0].content_name.substring(0, 14)
								: similarMovies[0].content_name}
						</span> */}
					</div>
				) : (
					<p className="mb-md-4 mt-md-2" style={{ marginLeft: "32px" }}>
						Loading...
					</p>
				)}
			</div>
		</div>
	);
};

export default MovieSlider;
