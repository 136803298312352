// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-player-page {
    background: #2D132C fixed;
    transition: 1s ease-in-out
}`, "",{"version":3,"sources":["webpack://./src/components/MediaPlayer/MediaPlayer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB;AACJ","sourcesContent":[".video-player-page {\n    background: #2D132C fixed;\n    transition: 1s ease-in-out\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
