import React,{useEffect,useState} from 'react';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios'

const Account=(props)=>{
	const currentUser = localStorage.getItem('currentUser');
	const currentProfile = localStorage.getItem("profile_name");
	const profileIcon = props.profileIcon ? props.profileIcon : localStorage.getItem("profile_icon")

	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErrorOpen, setSnackErrorOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertErrorMessage, setAlertErrorMessage] = useState("");
	const [profileState, setProfile] = useState({firstName:'', lastName:'', email:''});
	const [user_, setUser] = useState()
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL});
	const [profileLoading, setProfileLoading] = useState(false);	

	const getUser = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`,{ headers:{'Authorization': `Bearer ${localStorage.getItem('token')}`}})
		const {data} =  resp.data;
		if(resp.data.success){
			setUser(data)
		}else{
			console.log(resp.data.message)
		}
	}

	const updateUser= async (e)=>{
		try {
			setProfileLoading(true);
			e.preventDefault()
			let fullName = profileState.firstName+" "+profileState.lastName;
	
			const body = {
				fullName: fullName.trim(),
				email:profileState.email.trim()
			}
			const resp = await axios_.put(`${currentUser}/user`, body);
			
			if(resp.data.success){
				const {data} = resp.data
				setProfileLoading(false);
				setSnackOpen(true);
				setAlertMessage("Profile updated successfully!")
	
				let name = data.fullName.split(" ");   
				let firstName = name[0] ;
				let lastName = name[1];
				setProfile({...profileState, firstName:firstName, lastName:lastName, email:data.email})
				//show alert
			} else {
				setProfileLoading(false);
				setSnackErrorOpen(true);
				setAlertErrorMessage(resp.data.message);
			}
		} catch (error) {
			setProfileLoading(false);
			setSnackErrorOpen(true);
			setAlertErrorMessage('Error Occured');
		}

	}


	useEffect(()=>{
		getUser()
	},[])

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackOpen(false);
		setSnackErrorOpen(false);
	};

	const action = (
		<>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleSnackBarClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	return(
		<div className="container">
			<div className="row mt-5 mb-5">
				<div className="col-md-12"> 
					<form onSubmit={updateUser} action="" method="post">
						<div className="row d-flex">
							<div className="col-md-6">
								{
									user_ ?
										(user_.profiles[0].profile_name === currentProfile ? 
											<input onChange={(e)=>setProfile({...profileState, firstName:e.target.value})} className="form-control my-3" type="text" name="firstName" defaultValue={user_ ? user_.fullName.split(" ")[0] : ""}  placeholder="First name" required/>
											:<input onChange={(e)=>setProfile({...profileState, firstName:e.target.value})} style={{backgroundColor:'inherit'}} className="form-control mb-3" type="text" name="firstName" defaultValue={user_ ? user_.fullName.split(" ")[0] : ""}  placeholder="First name" required disabled/>
										)
										: null
								}
							</div>

							<div className="col-md-6">
								{
									user_ ?
										(user_.profiles[0].profile_name === currentProfile ? 
											<input onChange={(e)=>setProfile({...profileState, lastName:e.target.value})} className="form-control my-3" name="lastName" type="text" defaultValue={user_ ? user_.fullName.split(" ")[1]: ""}  placeholder="Last name" required/>
											:<input onChange={(e)=>setProfile({...profileState, lastName:e.target.value})} style={{backgroundColor:'inherit'}} className="form-control mb-3" name="lastName" type="text" defaultValue={user_ ? user_.fullName.split(" ")[1]: ""}  placeholder="Last name" required disabled/>
										)
										: null
								}
							</div>
						</div>

						<div className="row">
							<div className="col-md-6">
								{
									user_ ?
										(user_.profiles[0].profile_name === currentProfile ? 
											<input onChange={(e)=>setProfile({...profileState, email:e.target.value})} className="form-control mt-3" name="email" type="email" defaultValue={user_ ? user_.email: ""} placeholder="email" required/>
											:<input onChange={(e)=>setProfile({...profileState, email:e.target.value})} style={{backgroundColor:'inherit'}} className="form-control mt-md-3 mb-3" name="email" type="email" defaultValue={user_ ? user_.email: ""} placeholder="email" required disabled/>
										)
										: null
								}
							</div>
						</div>

						{user_ ?
							(user_.profiles[0].profile_name === currentProfile ?
								<div className="row">
									<div className="col-md-12">
										<button className="edit-prof-btn py-1 px-5 update-prof-button" type="submit">
											{!profileLoading ? (
												"Update"
											) : (
												<CircularProgress
													style={{
														width: "20px",
														height: "20px",
													}}
													color="inherit"
												/>
											)}
										</button>
									</div>
								</div> : null
							) 
							: null
						}
					</form>
				</div>
			</div>
			{snackOpen ? (
				<Snackbar
					open={snackOpen}
					autoHideDuration={8000}
					onClose={handleSnackBarClose}
					message={alertMessage}
					action={action}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Alert
						style={{ marginBottom: "1rem" }}
						icon={<CheckCircleOutlineIcon fontSize="inherit" />}
						sx={{ width: "100%" }}
						severity="success"
						onClose={handleSnackBarClose}
					>
						{alertMessage}
					</Alert>
				</Snackbar>
			) : null}
			
			{snackErrorOpen ? (
				<Snackbar
					open={snackErrorOpen}
					autoHideDuration={8000}
					onClose={handleSnackBarClose}
					message={alertErrorMessage}
					action={action}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Alert
						style={{ marginBottom: "1rem" }}
						icon={<ErrorIcon fontSize="inherit" />}
						sx={{ width: "100%" }}
						severity="error"
						onClose={handleSnackBarClose}
					>
						{alertErrorMessage}
					</Alert>
				</Snackbar>
			) : null}
		</div>
	)

}

export default Account;