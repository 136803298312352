import axios from "axios";

const API_KEY = process.env.REACT_APP_CURRENCY_CONVERTER;

export const convertUSDToNGN = async (usdAmount) => {
	try {
		// Fetch the conversion rate
		const response = await axios.get(`https://apilayer.net/api/live?access_key=${API_KEY}&currencies=NGN`);
		const rate = response.data.quotes.USDNGN;

		// Calculate the NGN amount
		const ngnAmount =  Math.ceil(usdAmount * rate);
		return new Intl.NumberFormat("en-NG", {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(ngnAmount);
	} catch (error) {
		console.error("Error fetching conversion rate:", error);
		throw new Error("Failed to fetch conversion rate");
	}
};
