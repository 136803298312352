// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-o4b71y-MuiAccordionSummary-content{
    justify-content: space-between;
    color: white;
}
.css-1xbyrci-MuiPaper-root-MuiAccordion-root:first-of-type {
    border-radius: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/customaccordion.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;AAChB;AACA;IACI,8BAA8B;AAClC","sourcesContent":[".css-o4b71y-MuiAccordionSummary-content{\n    justify-content: space-between;\n    color: white;\n}\n.css-1xbyrci-MuiPaper-root-MuiAccordion-root:first-of-type {\n    border-radius: 15px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
