import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../apiClient";
import NavBar from "../Nav/NavBar";
import TopNavBar from "../Nav/TopNavBarV2";
import BottomNav from "../Nav/BottomNav";
import Footer from "../Footer/Footer";
import axios from "axios";
import "./PricingPlan.css";
import { convertUSDToNGN } from "../../utils/currency";

const PricingPlan = () => {
	const [plan, setPlan] = useState([]);
	const [plans, setPlans] = useState({});
	const [message, setMessage] = useState("");
	const [isFooter, setFooter] = useState({footerActive: true,bottomNavActive: false,});
	const [newPrice, setNewPrice] = useState(null);
	const [newPrice1, setNewPrice1] = useState(null);
	const [newPrice2, setNewPrice2] = useState(null);

	const currentUser = localStorage.getItem('currentUser')
	const region = localStorage.getItem("region");
	const profileId = localStorage.getItem("selected_prof_id");
	let _region = region ? JSON.parse(region) : {};

	const location = useLocation();
	const navigate = useNavigate();

	//Determine Mobile or not
	useEffect(() => {
		if ($(window).width() <= 600) {
			setFooter({
				...isFooter,
				bottomNavActive: true,
				footerActive: false,
			});
		} else {
			setFooter({
				...isFooter,
				bottomNavActive: false,
				footerActive: true,
			});
		}
	}, []);

	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

	const getPlans = async () => {
		const res = await axios_.get("/plans");
		if (res.status === 200) {
			setPlan(res.data.plans);
		}
	};

	useEffect(() => {
		const storedMessage = sessionStorage.getItem("redirectMsg");
		if (storedMessage) {
			setMessage(storedMessage);
			sessionStorage.removeItem("redirectMsg");
		}
	}, []);

	useEffect(() => {
		getPlans();
		if (location.state?.message) {
			setMessage(location.state?.message);
		}
		window.scrollTo(0, 0);
	}, []);

	const windowWidth = $(window).width();

	const convertAmounts = async (prices) => {
		const conversions = await Promise.all(
			prices.map((price) => convertUSDToNGN(price))
		);
		setNewPrice(conversions[0]);
		setNewPrice1(conversions[1]);
		setNewPrice2(conversions[2]);
	};

	useEffect(() => {
		if (plan && plan.length > 0) {
			const prices = plan.slice(0, 3).map((p) => p.price);
			convertAmounts(prices);
		}
	}, [plan]);

	const getOnlyPlans = async () => {
		const res = await axiosInstance.get("/plans");
		if (res.status === 200) {
			setPlans(res.data);
		}
	};

	useEffect(() => {
		getOnlyPlans();
	}, []);

	return plan.length ? (
		<div className="bg-pricingplan">
			{windowWidth >= 600 ? 
				currentUser && currentUser.length > 0 ? (<TopNavBar region={_region[profileId]} />) : (<NavBar />) : null
			}

			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="pricing-plan-text mt-md-5">Pricing Plan</h1>
						<div className="d-flex">
							<div className="light-pink-water mt-md-2"></div>{" "}
							<div className="light-pink-water-two ml-2 mt-md-2"></div>
						</div>
						<p className="pricing-p mt-5">
							Afrowatch gives room for various plans to meet your
							desired needs. The plan chosen determines, the
							number of screens at which you can access content at
							the same time as well as High or Ultra high
							definition quality.
						</p>

						<p className="pricing-p mt-1">
							<span style={{color:'#12C38D', fontSize:'26px'}}>You also get <span style={{textDecoration:'underline'}}>3 months</span> free trial!</span> 
						</p>
						{ message ? (<div className="py-2 px-2" style={{color: "white", background: "#C92941", width: "30%",borderRadius: "5px",}}>{message}</div>) : null}
					</div>
				</div>

				{windowWidth >= 600 ? (
					<div className="row sec2-row justify-content-center">
						<div className="col-md-10">
							<table>
								<thead>
									<tr className="bg-first-row">
										<td className="text-center pl-md-4">
											{/* <FormControlLabel
												control={<PriceSwitch sx={{ m: 1 }}/>}
												label="Monthly/Yearly"
											/> */}
										</td>

										<td className="text-center px-md-5">
											<p className="plans">
												{plan[0].subscription_tier}
											</p>
											<p className="price">
												{plans.ipInformation && plans.ipInformation.country === "Nigeria" ? `NGN ${newPrice} `: `$${plan[0].price} `}per month
											</p>
											<Link
												to={currentUser ? '/pricing/start-your-plan/basic' : '/signup'}
												state={{
													planName:plan[0].subscription_tier,
													price: plan[0].price,
													nollyWood:plan[0].region_allowed.includes(["W"]),
													gollyWood:plan[0].region_allowed.includes(["W"]),
													highDefinition:plan[0].high_definition,
													screens: plan[0].screens,
													devices: plan[0].screens,
													ultraHD:plan[0].high_definition,
													priceId:plan[0].stripe_price_id,
													productId:plan[0].stripe_product_id,
													alertMessage: !currentUser ? 'Sign up to purchase a plan!' : null
												}}
												className="btn btn-primary purchase-now-btn mx-1 mx-md-0"
											>
												Purchase now
											</Link>
										</td>

										<td className="text-center px-md-5">
											<p className="plans">
												{plan[1].subscription_tier}
											</p>
											<p className="price">
												{plans.ipInformation && plans.ipInformation.country ==="Nigeria"? `NGN ${newPrice1} `: `$${plan[1].price} `} per month
											</p>
											<button
												disabled
												className="py-2 px-2 coming-soon-btn mx-1 mx-md-0"
											>
												Coming Soon
											</button>
											{/* <Link to='/pricing/start-your-plan/mid' state={{
												planName: plan[1].subscription_tier, 
												price:plan[1].price, 
												nollyWood:plan[1].region_allowed.includes(["W"]),
												gollyWood: plan[1].region_allowed.includes(["W"]),
												highDefinition: plan[1].high_definition,
												screens: plan[1].screens,
												devices: plan[1].screens,
												ultraHD: plan[1].high_definition,
												priceId: plan[1].stripe_price_id,
												productId: plan[1].stripe_product_id
											}} className="btn btn-primary purchase-now-btn mx-1 mx-md-0">Purchase now</Link> */}
										</td>

										<td className="text-center px-md-5">
											<p className="plans">
												{plan[2].subscription_tier}
											</p>
											<p className="price">
												{plans.ipInformation && plans.ipInformation.country === "Nigeria" ? `NGN ${newPrice2} `: `$${plan[2].price} `} per month
											</p>
											<button
												disabled
												className="py-2 px-2 coming-soon-btn mx-1 mx-md-0"
											>
												Coming Soon
											</button>
											{/* <Link to='/pricing/start-your-plan/premium' state={{
												planName: plan[2].subscription_tier, 
												price:plan[2].price, 
												nollyWood:plan[2].region_allowed.includes(["W"]),
												gollyWood: plan[2].region_allowed.includes(["W"]),
												highDefinition: plan[2].high_definition,
												screens: plan[2].screens,
												devices: plan[2].screens,
												ultraHD: plan[2].high_definition,
												priceId: plan[2].stripe_price_id,
												productId: plan[2].stripe_product_id
											}} className="btn btn-primary purchase-now-btn mx-1 mx-md-0">Purchase now</Link> */}
										</td>
									</tr>
								</thead>

								<tbody>
									<tr className="light-purple">
										<td className="text-center px-md-4 py-md-3 py-2">Nigeria</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
									</tr>

									<tr className="dark-purple-row">
										<td className="text-center px-md-4  px-3 py-md-3 py-2">Ghana</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
									</tr>

									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-md-3">
											South Africa
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
									</tr>

									<tr className="dark-purple-row">
										<td className="text-center px-md-4 px-3 py-3">Devices</td>
										{/* {plan[0].screens} */}
										<td className="text-center px-md-4 py-3">Unlimited</td>
										{/* {plan[1].screens} */}
										<td className="text-center px-md-4 py-md-3">Coming soon</td>
										{/* {plan[2].screens} */}
										<td className="text-center px-md-4 py-md-3">Coming soon</td>
									</tr>

									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-3">Screens</td>
										{/* {plan[0].screens} */}
										<td className="text-center px-md-4 py-3">Unlimited</td>
										{/* {plan[1].screens} */}
										<td className="text-center px-md-4 py-md-3">Coming soon</td>
										{/* {plan[2].screens} */}
										<td className="text-center px-md-4 py-md-3">Coming soon</td>
									</tr>

									<tr className="dark-purple-row">
										<td className="text-center pl-md-4 py-md-3">Unlimited animation</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
									</tr>

									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-md-3">
											Laptop, phone or tablet
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
									</tr>

									<tr className="dark-purple-row">
										<td className="text-center px-md-4 px-3 py-md-3">
											HD available
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
									</tr>

									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-3">
											Ultra HD available
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
										<td className="text-center px-md-4 py-md-3">
											<img
												src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
												alt=""
											/>
										</td>
									</tr>

									<br />
								</tbody>
							</table>
						</div>
					</div>
				) : (
					<div className="row mt-5 mb-5">
						{plan.map((p, index) => {
							return (
								<div key={index} className="col-12">
									<div className="card price-subscribe-card">
										<h1 className="start-plan-name text-center py-3">{p.subscription_name}</h1>
										<p className="start-plan-price">
											{p.subscription_name === "basic-plan" && (
												<p className="price">
													{plans.ipInformation && plans.ipInformation.country === "Nigeria" ? `NGN ${newPrice} `: `$${p.price}}`} per month
												</p>
											)}
											{p.subscription_name === "mid-plan" && (<p className="price">{plans.ipInformation && plans.ipInformation.country === "Nigeria" ? `NGN ${newPrice1} `: `$${p.price}`} per month</p>)}
											{p.subscription_name ===
												"premium-plan" && (
												<p className="price">
													{plans.ipInformation && plans.ipInformation.country === "Nigeria" ? `NGN ${newPrice2} `: `$${p.price}`} per month
												</p>
											)}
										</p>
										<hr className="line-break" />

										<div className="card-body">
											<div className="start-plan-list">
												<li className="py-2">
													Nigeria
													{!p.region_allowed.includes(["W"]) ? (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
															alt=""
														/>
													) : (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg"
															alt=""
														/>
													)}
												</li>
												<li className="py-2">
													Ghana
													{!p.region_allowed.includes(["W"]) ? (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
															alt=""
														/>
													) : (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg"
															alt=""
														/>
													)}
												</li>
												<li className="py-2">
													South Africa
													{!p.region_allowed.includes(["W"]) ? (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
															alt=""
														/>
													) : (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg"
															alt=""
														/>
													)}
												</li>
												<li className="py-2">
													Devices{" "}
													<span className="start-plan-tick">
														{p.subscription_tier === "MEDIUM" || p.subscription_tier === "PREMIUM" ? 'Coming Soon' :'Unlimited'}
														{/* {p.screens}{" "} */}
													</span>
												</li>
												<li className="py-2">
													Screens{" "}
													<span className="start-plan-tick">
														{p.subscription_tier === "MEDIUM" || p.subscription_tier === "PREMIUM" ? 'Coming Soon' :'Unlimited'}
														{/* {p.screens}{" "} */}
													</span>
												</li>
												<li className="py-2">
													High Definition{" "}
													{p.high_definition ? (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
															alt=""
														/>
													) : (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg"
															alt=""
														/>
													)}
												</li>
												<li className="py-2">
													Ultra HD
													{p.high_definition ? (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
															alt=""
														/>
													) : (
														<img
															className="mt-1 start-plan-tick"
															src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg"
															alt=""
														/>
													)}
												</li>
												<li className="py-2">
													Watch on your Laptop, tablet or phone{" "}
													<img
														className="mt-1 start-plan-tick"
														src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg"
														alt=""
													/>
												</li>
											</div>

											<hr className="line-break" />
											{p.subscription_tier === "MEDIUM" || p.subscription_tier === "PREMIUM" ? (
												// <Link
												// 	to={`#`}
												// 	state={{
												// 		planName:p.subscription_tier,
												// 		price: p.price,
												// 		nollyWood:
												// 		p.region_allowed.includes(["W"]),
												// 		gollyWood:p.region_allowed.includes(["W"]),
												// 		highDefinition:p.high_definition,
												// 		screens: p.screens,
												// 		devices: p.screens,
												// 		ultraHD:p.ultraHighDefinition,
												// 		priceId:p.stripe_price_id,
												// 		productId:p.stripe_product_id,
												// 	}}
												// 	style={{ opacity: 0.65 }}
												// 	className="btn btn-primary purchase-now-btn-mobile_pp mx-1 mx-md-0"
												// >
												// Coming soon
												// </Link>
												<button
													disabled
													className="btn btn-primary purchase-now-btn-mobile_pp mx-1 mx-md-0"
												>
													Coming Soon
												</button>
											) : (
												<Link
													to={`/pricing/start-your-plan/${p.subscription_name}`}
													state={{
														planName:p.subscription_tier,
														price: p.price,
														nollyWood:p.region_allowed.includes(["W"]),
														gollyWood:p.region_allowed.includes(["W"]),
														highDefinition:p.high_definition,
														screens: p.screens,
														devices: p.screens,
														ultraHD:p.ultraHighDefinition,
														priceId:p.stripe_price_id,
														productId:p.stripe_product_id,
													}}
													className="btn btn-primary purchase-now-btn-mobile_pp mx-1 mx-md-0"
												>
												Purchase now
												</Link>
											)}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
			{isFooter.footerActive ? (
				<Footer />
			) : (
				<BottomNav className="d-block d-md-none d-sm-block" />
			)}
		</div>
	) : null;
};

export default PricingPlan;
