import React, { useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import { IoNotificationsOutline } from 'react-icons/io5';
import MovieCard from '../MoviesPage/MovieCardComponent';
import './Notification.css';

const Notification = () => {
	const { isModalOpen, closeModal, openModal } = useContext(ModalContext);

	const toggleModal = (e) => {
		isModalOpen ? closeModal() : openModal();
	};

	const notifications = [
		{
			title: "New Arrival",
			description: "Zog and the Flying Doctors",
			day: "2 days ago",
			image: "https://media.istockphoto.com/id/1799054435/photo/happy-black-father-and-daughter-enjoying-in-movie-projection-in-theater.webp?b=1&s=170667a&w=0&k=20&c=WXyjfZbDno3K5ampku0z5BkA8XxCJ9JRBkL_EXSuCig="
		},
		{
			title: "Now Available!",
			description: "Zog and the Flying Doctors",
			day: "1 week ago",
			image: "https://images.unsplash.com/photo-1523789863828-dd2877f89725?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTh8MTk3OTM2MHx8ZW58MHx8fHx8"
		},
		{
			title: "Continue Watching",
			description: "Zog and the Flying Doctors",
			day: "2 weeks ago",
			image: "https://images.unsplash.com/photo-1529390079861-591de354faf5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
		},
		{
			title: "Continue Watching",
			description: "Zog and the Flying Doctors",
			day: "2 weeks ago",
			image: "https://images.unsplash.com/photo-1529390079861-591de354faf5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
		},
		{
			title: "Continue Watching",
			description: "Zog and the Flying Doctors",
			day: "2 weeks ago",
			image: "https://images.unsplash.com/photo-1529390079861-591de354faf5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
		}
	];

	return (
		<div className="notification-container position-relative">
			<div className="notification" onClick={toggleModal}>
				<IoNotificationsOutline className="text-white" style={{ fontSize: '1.5rem' }}/>
				{/* notification length */}
				<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">0</span>
			</div>
			
			{isModalOpen && (
				<div className="notification-modal" onClick={closeModal}>
					<div className="notification-modal-content">
						<div className="notification-modal-header">Notifications</div>
						<div className="notification-modal-body">
							{notifications.map((notification, index) => (
								<div className="notification-item" key={index}>
									<div className="notification-movie-card">
										<MovieCard
											image={notification.image}
											hidePlayButton
										/>
									</div>
									<div className="notification-text ml-5">
										<div className="mt-1">{notification.title}</div>
										<div className="mt-1">{notification.description}</div>
										<div className="mt-1 day">{notification.day}</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Notification;
