// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-img{
    width:54px;
    height:54px;
    border-radius:10px
}

@media (max-width:600px) {
    .navbarbrand-img {
        height: 30px;
        width: 180px;
    }
    .profile-img{
        width:34px;
        height:34px;
        border-radius:10px
    }
}

@media (min-width:600px) {
    .navbarbrand-img {
        height: 60px;
        width: 180px;
    }
}

.navbar-nav .nav-link {
    background-color: #AE2438;
    border-radius: 5px;
    height: 40px;
}

.navbar-nav .nav-link:hover {
    background-color: #06BA83;
    color: white!important;
    border-radius: 5px;
    height: 40px;
    transition: 400ms ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/Nav/NavBar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;IACA;QACI,UAAU;QACV,WAAW;QACX;IACJ;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;AACJ;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;AACjC","sourcesContent":[".profile-img{\n    width:54px;\n    height:54px;\n    border-radius:10px\n}\n\n@media (max-width:600px) {\n    .navbarbrand-img {\n        height: 30px;\n        width: 180px;\n    }\n    .profile-img{\n        width:34px;\n        height:34px;\n        border-radius:10px\n    }\n}\n\n@media (min-width:600px) {\n    .navbarbrand-img {\n        height: 60px;\n        width: 180px;\n    }\n}\n\n.navbar-nav .nav-link {\n    background-color: #AE2438;\n    border-radius: 5px;\n    height: 40px;\n}\n\n.navbar-nav .nav-link:hover {\n    background-color: #06BA83;\n    color: white!important;\n    border-radius: 5px;\n    height: 40px;\n    transition: 400ms ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
