import { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Footer from '../Footer/Footer';
import BottomNav from '../Nav/BottomNav';
import MovieCard from '../MoviesPage/MovieCardComponent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import NavBar from '../Nav/TopNavBarV2';
import useThemeStyle from '../../hook/useThemeStyle';
import getUser from '../../utils/user';
import './index.css'

const NewArrivalsPage = () => {
	const [newArrival, setNewArrival] = useState([]);
	const [isFooter, setFooter] = useState({ footerActive: true, bottomNavActive: false });
	const [isNavBarSearch, setNavBarSearch] = useState({ active: true });
	const { closeModal } = useContext(ModalContext);
	const settingStyle = useThemeStyle();


	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const profileName = localStorage.getItem("profile_name");
	const profileIcon = localStorage.getItem("profile_icon");
	const profileId = localStorage.getItem("selected_prof_id");
	const regionObj = localStorage.getItem("region") ? JSON.parse(localStorage.getItem("region")): {};
	const region = regionObj[profileId];

	const navigate = useNavigate()



	const handleNavView = () => {
		//Determine Mobile or not
		if (window.innerWidth <= 600) {
			setFooter({ ...isFooter, footerActive: false, bottomNavActive: true });
			setNavBarSearch({ ...isNavBarSearch, active: false });
		} else {
			setFooter({ ...isFooter, footerActive: true, bottomNavActive: false });
			setNavBarSearch({ ...isNavBarSearch, active: true });
		}
	};

	const getContentNewArrivals = async () => {
		try {
			const res = await axios_.get(`/content/n/new-arrivals`);
			if (res.data.status === 200) {
				setNewArrival(res.data.content);
			} else {
				console.log(res.data.message);
			}
		} catch (error) {
			console.error('Error fetching new arrivals content:', error);
		}
	};

	const checkUserPlanExists =  async () => {
		const user =  await getUser();
		if (user) {
			if (user.currentPlan === null) {
				navigate('/pricing-plan', { state: { message: 'You need a plan to view content 😊' }})
			} 
		}
	};
	const handleGoBack = () => {
		navigate(-1);
	}

	useEffect(()=>{
		checkUserPlanExists();

		//only if you have a plan
		//setTimeout(()=>{navigate('/whos-watching')} ,2000)
	},[])

	useEffect(() => {
		getContentNewArrivals();
		handleNavView()
	}, []);


	return (
		<div>
			<div className="bg-listPage" onClick={() => closeModal()}>
				<NavBar profile={profileName} profileIcon={profileIcon} region={region}/>
				<div className="container">

					<div className="d-flex mb-5 mt-md-5">
						<div className="mr-2">
							<ArrowBackIcon fontSize={'small'} sx={{cursor: 'pointer', color:'white'}} className="bg-none" style={settingStyle}  onClick={handleGoBack}/>
						</div>
						<div><h3 className="bg-none" style={settingStyle}>New Arrivals</h3>
							<div className="d-flex">
								<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '40px', marginRight: '5px', opacity: 1,}}></Divider>
								<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '5px', opacity: 1,}}></Divider>
							</div>
						</div>
					</div>

					<div className={`row justify-content-center align-items-center`}>
						{newArrival && newArrival.length > 0 ?  newArrival.map((content, pos) => (
							<div key={pos} className="col-6 col-md-4 col-lg-4  mb-4">
								<div className="movie-show">
									<MovieCard
										image={content.video_image_url ? content.video_image_url : 'https://d2fft6uz2yutyc.cloudfront.net/default_video_image.png'}
										content_id={content.content_id}
										actors={content.actors}
										content_name={content.content_name}
										date_uploaded={content.date_uploaded}
										genre={content.genre}
										video_path={content.video_path}
										length={content.length}
										type={content.content_type}
										description={content.content_description ? content.content_description : ""}
										header_image={content.video_header_image_url ? content.video_header_image_url : 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1708159982/default_content_header_uvxcnu.png'}
									/>
								</div>
							</div>
						)):
							(
								<div className='row justify-content-center w-100'>
									<div className='col-md-6'>
										<div className='mt-5'>
											<img className='empty_list_Box' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1717212230/Empty-amico_1_kfwaoz.png" alt='empty_list_box'/>
											<h4 className='text-center pt-5 pb-2 watchlist_Text text-white'>No new arrivals yet. </h4>
										</div>
									</div>
								</div>
							)
						}

					</div>
				</div>
				{isFooter.footerActive ? <Footer /> : <BottomNav className="d-block d-md-none d-sm-block" />}
			</div>
		</div>
	);
};

export default NewArrivalsPage;
