import React, { useState, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from '../components/Nav/NavBar';
import ThemeContextProvider, { ThemeContext } from '../context/ThemeContext';
import ModalContextProvider from '../context/ModalContext';
import { AuthContext } from '../context/AuthContext';
import Home from './Home/Home';
import SignUp from '../components/Authentication/SignUp';
import LogIn from '../components/Authentication/LogIn';
import Verify from '../components/Authentication/Verify';
import ForgotPassword from './Authentication/ForgotPassword';
import CheckEmail from './Authentication/CheckEmail';
import ResetPassword from './Authentication/ResetPassword';
import SplashScreen from '../components/SplashScreen/SplashPage';
import WhosWatching from '../components/WhosWatching/WhosWatching';
import MoviesPage from '../components/MoviesPage/MoviesPage';
import FilterPage from './Filter/FilterContent';
import NollyWoodPage from '../components/MoviesPage/NollyWoodPage';
import MovieDescriptionPage from '../components/MovieDescription/MovieDescPage';
import MediaPlayerPage from '../components/MediaPlayer/MediaPlayerPage';
import FeaturedMediaPlayerPage from './MediaPlayer/FeaturedMediaPlayer';
import AfrimePage from '../components/Afrime/AfrimePage';
import AfrimeSelection from '../components/Afrime/AfrimeSelection';
import EducationPage from './Afrime/EducationPage';
import PricingPlanPage from '../components/Pricing/PricingPlan';
import StartYourPlan from '../components/Pricing/StartYourPlan';
import ProfilePage from '../components/Profile/Profile';
import Search from './Search/Search';
import ListPage from './ListPage';
import NewArrivalsPage from './NewArrivals';
import DownloadPage from './DownloadPage';
import Shorts from './Shorts/Shorts';
import NotFound from './NotFound/NotFound';
import EditProfileWhosWatching from './WhosWatching/EditProfileWhosWatching';

import '../utils/i18n';
import './App.css';
import { datadogRum } from '@datadog/browser-rum';
import TermsOfUse from './Legal/TermsOfUse';
import PrivacyPolicy from './Legal/PrivacyPolicy';

datadogRum.init({
	applicationId: process.env.REACT_APP_DD_APP_ID,
	clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
	site: 'datadoghq.com',
	service: 'afrowatch-beta',
	env: process.env.REACT_APP_ENV,
	version: '1.0.0',
	sessionSampleRate: 100,
	sessionReplaySampleRate: 20,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
});

const App = () => {
	const [user, setUser] = useState('');

	// protected routes
	const requireAuth = (nextState, replace) => {
		if (user === '') {
			replace('/login');
		}
	};

	return (
		<BrowserRouter>
			<React.Fragment>
				<ThemeContextProvider>
					<ModalContextProvider>
						<div className="App">
							<AuthContext.Provider value={{ user, setUser }}>
								<Routes>
									{/* <NavBar routeBg={setPageBg}/> */}
									<Route path="/" element={<LogIn />} />
									<Route path="/splash" element={<SplashScreen />} />
									<Route path="/notify" element={<Home />} />
									<Route path="/login" element={<LogIn />} />
									<Route path="/signup" element={<SignUp />} />
									<Route path="/forgot-password" element={<ForgotPassword />} />
									<Route path="/check-email" element={<CheckEmail />} />
									<Route path="/reset-password" element={<ResetPassword />} />
									<Route path="/verify" element={<Verify />} />
									<Route path="/whos-watching" element={<WhosWatching />} />
									<Route exact path='/terms-of-use' element={<TermsOfUse />} />
									<Route exact path='/privacy-policy' element={<PrivacyPolicy />} />


									<Route exact path='/profile/:userid/watch' element={<MoviesPage />} />
									<Route exact path='/profile/:userid/watch/Nigeria' element={<NollyWoodPage />} />
									<Route exact path='/profile/:userid/watch/:filter' element={<FilterPage />} />
									<Route exact path='/profile/:userid/search' element={<Search />} />
									<Route exact path='/my-list' element={<ListPage />} />
									<Route exact path='/new-arrivals' element={<NewArrivalsPage />} />
									<Route exact path='/downloads' element={<DownloadPage />} />
									<Route exact path='/afclips' element={<Shorts />} />
                    
									<Route exact path='/edit-profile' element={<EditProfileWhosWatching/>}/>
									<Route exact path='/new-profile' element={<EditProfileWhosWatching new_profile={true}/>}/>

									<Route path='/profile/:userid/watch/afrime' element={<AfrimeSelection />} />
									<Route path='/profile/:userid/afrime/education' element={<EducationPage />} />
									<Route exact path='/profile/:userid' element={<ProfilePage />} />
									<Route exact path='/profile/:userid/edit' element={<ProfilePage />} />

									<Route exact path='/watch/:contentid' element={<MovieDescriptionPage />} />
									<Route path='/watch/:contentid/play' element={<MediaPlayerPage />} />
									<Route path='/watch/:contentid/f/play' element={<FeaturedMediaPlayerPage />} />
									<Route exact path='/pricing-plan' element={<PricingPlanPage />} />
									<Route exact path='/pricing/start-your-plan/:planid' element={<StartYourPlan />} />
									{/* 404 page doesn't exist */}
									<Route path='*' element={<NotFound />} />
								</Routes>
							</AuthContext.Provider>
						</div>
					</ModalContextProvider>
				</ThemeContextProvider>
			</React.Fragment>
		</BrowserRouter>
	);
};

export default App;
