// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-listPage {
    background: url("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png"), #311430;
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    /* padding-top: 5.4rem; */
    background-color: #2D132C;
}

.home-btn {
    background-color: #2D132C;
    border-radius: 5px;
    border: 2px #C92941 solid;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    color: #C92941;
    position: relative;
    left: 150px;
    top: 25px;
}

.empty_list_Box{
    padding-left: 75px;
    padding-right: 75px;
}

.creating_watchlist_Text{
    font-weight: 100;
}

.home-btn:hover {
    text-decoration: none;
    color: #FFFFFF;
}

@media (max-width:600px) {
    .bg-listPage {
        padding-bottom: 5rem;
    }

    .empty_list_Box{
        width: 220px;
        padding: 0px;
        margin-left: 60px;
        margin-right: 60px;
    }

    .home-btn {
        left: 60px
    }

    .watchlist_Text{
        font-size: 18px;
    }

    .creating_watchlist_Text{
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ListPage/style/index.css"],"names":[],"mappings":"AAAA;IACI,oHAAoH;IACpH,4BAA4B;IAC5B,yBAAyB;IACzB,sBAAsB;IACtB,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI;QACI,oBAAoB;IACxB;;IAEA;QACI,YAAY;QACZ,YAAY;QACZ,iBAAiB;QACjB,kBAAkB;IACtB;;IAEA;QACI;IACJ;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".bg-listPage {\n    background: url(\"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png\"), #311430;\n    background-repeat: no-repeat;\n    background-position: left;\n    background-size: cover;\n    /* padding-top: 5.4rem; */\n    background-color: #2D132C;\n}\n\n.home-btn {\n    background-color: #2D132C;\n    border-radius: 5px;\n    border: 2px #C92941 solid;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 27px;\n    color: #C92941;\n    position: relative;\n    left: 150px;\n    top: 25px;\n}\n\n.empty_list_Box{\n    padding-left: 75px;\n    padding-right: 75px;\n}\n\n.creating_watchlist_Text{\n    font-weight: 100;\n}\n\n.home-btn:hover {\n    text-decoration: none;\n    color: #FFFFFF;\n}\n\n@media (max-width:600px) {\n    .bg-listPage {\n        padding-bottom: 5rem;\n    }\n\n    .empty_list_Box{\n        width: 220px;\n        padding: 0px;\n        margin-left: 60px;\n        margin-right: 60px;\n    }\n\n    .home-btn {\n        left: 60px\n    }\n\n    .watchlist_Text{\n        font-size: 18px;\n    }\n\n    .creating_watchlist_Text{\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
