// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-editProfileForm{
    background-color: #2D132C;
    background-image: url('https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full screen height */
    align-items: center;  /* Optional, for vertical centering */
    justify-content: center;  /* Optional, for horizontal centering */
}

.profile-logo{
    width: 10%;
    margin: auto;
    display: block;
}

@media(max-width:600px){
    .profile-logo{
        width: 30%;
        margin: auto;
        display: block;
    } 
}`, "",{"version":3,"sources":["webpack://./src/components/WhosWatching/EditProfileWhosWatching.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iHAAiH;IACjH,4BAA4B;IAC5B,sBAAsB;IACtB,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa,EAAE,uBAAuB;IACtC,mBAAmB,GAAG,qCAAqC;IAC3D,uBAAuB,GAAG,uCAAuC;AACrE;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;QACV,YAAY;QACZ,cAAc;IAClB;AACJ","sourcesContent":[".bg-editProfileForm{\n    background-color: #2D132C;\n    background-image: url('https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh; /* Full screen height */\n    align-items: center;  /* Optional, for vertical centering */\n    justify-content: center;  /* Optional, for horizontal centering */\n}\n\n.profile-logo{\n    width: 10%;\n    margin: auto;\n    display: block;\n}\n\n@media(max-width:600px){\n    .profile-logo{\n        width: 30%;\n        margin: auto;\n        display: block;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
