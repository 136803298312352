import {useState, useEffect} from 'react'
import { useNavigate, useLocation} from 'react-router-dom'
import ProfileIcon from '../Profile/ProfileIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import Footer from '../Footer/Footer'
import axios from 'axios'
import { Link } from 'react-router-dom';
import './EditProfileWhosWatching.css'

const EditProfileWhosWatching =(props)=>{
	const [currentProfile,setCurrentProfile] = useState({profile:{}})
	const [profileState,setprofileState] = useState({name:'',icon:'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1627050044/AfroWatch/avatar-img-profile-aw-female_geet9b.jpg', defaultName: '', defaultIcon:'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1627050044/AfroWatch/avatar-img-profile-aw-female_geet9b.jpg'})
	const [profileIconFormActive,setProfileIconForm] = useState(false)

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const currentUser = localStorage.getItem("currentUser");
	const profileName = localStorage.getItem("profile_name");
	const navigate = useNavigate()
	const location = useLocation()

	const getProfile = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`, { headers:{'Authorization': `Bearer ${localStorage.getItem('token')}`}}); //profile_id
		const {data} =  resp.data;
		if(resp.data.success){
			console.log(resp.data);
			let profiles = data.profiles;

			for(let profile of profiles){
				if(profile.profile_name === profileName){
					setCurrentProfile({profile: profile });
					!props.new_profile ? setprofileState({name:profile.profile_name, icon:profile.profile_icon, defaultName:profile.profile_name, defaultIcon:profile.profile_icon}):null;

					console.log(profile.profile_icon)
					return;
				}
			}

		}else{
			console.log(resp.data.message)
			setCurrentProfile({profile:{}}) 
		}
	}

	const updateProfileName= async (e)=>{
		e.preventDefault()
		try{
			let fullName = profileState.name;
			const body = {
				fullName: fullName.trim(),
			}
			const resp = await axios_.put(`/${currentUser}/user/${currentProfile.profile._id}`, body)

			if(resp.data.success){
				const {data} = resp.data
				console.log(data)
				localStorage.setItem("profile_name",body.fullName)               
				navigate('/whos-watching',  {state: { profile: data ,message:'updated profile'}})
			}else{
				console.log(resp.data.message)
			}
		}catch(err){
			console.log(err)
		}
	}

	const addProfile = async(e)=>{
		e.preventDefault()
		const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

		const user = {
			name: profileState.name.trim()
		}

		const userId = localStorage.getItem('currentUser')

		const requestConfig = {
			method:'PUT',
			headers: { 'Content-Type': 'application/json' ,'Accept': 'application/json'},
			params: {userid: userId},
		}

		const resp = await axios_.put(`/${userId}/user/profile`, user, requestConfig)
		if(resp.data.success){
			const {data} = resp.data
			console.log(data)
			localStorage.setItem("profile_name",data.profile_name);
			navigate('/whos-watching',  {state: { profile: data, message:'added profile' }})

		}else{
			console.log(resp.data.message)
		}
	}

	useEffect(()=>{
		getProfile();
	},[])

	const handleGoBack = () => {
		navigate(-1);
	}

	return(
		<div className="bg-editProfileForm">
			<div className="container">
				<div className="d-flex mb-5 mt-5">
					<div className="mr-2">
						<ArrowBackIcon fontSize={'small'} sx={{cursor: 'pointer', color:'white'}}  onClick={handleGoBack}/>
					</div>
					<div>
						<h4 className="text-white">{`${location.pathname !== "/new-profile" ? "Edit Profile" : "Add Profile"}`}</h4>
						<div className="d-flex">
							<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '40px', marginRight: '5px', opacity: 1,}}></Divider>
							<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '5px', opacity: 1,}}></Divider>
						</div>
					</div>
				</div>
			</div>

			<div style={{paddingBottom:'120px'}}>
				<div className='row justify-content-center' style={{margin: 'auto'}}>
					<Link to="/" ><img src='https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png' alt="afrowatch-logo" className="profile-logo"/></Link>
				</div>
				<form to='/' className='col-12 col-md-8 row flex-column align-items-center mx-auto' onSubmit={(e)=>{if(!props.new_profile){updateProfileName(e)}else{addProfile(e);}}} action="" method="post">
					<div className='row justify-content-between align-items-center col-12 col-md-6 my-5 mx-auto px-0'>
						{props.new_profile ?
							<ProfileIcon isNewProfile profile_id={currentProfile.profile._id} profile_icon={profileState.icon}  setSwitch={()=>{profileIconFormActive ? setProfileIconForm(false):setProfileIconForm(true)}} left={true}/>:
							<ProfileIcon profile_id={currentProfile.profile._id} profile_icon={profileState.icon} setSwitch={()=>{profileIconFormActive ? setProfileIconForm(false):setProfileIconForm(true)}} left={true}/>
						}
						
						<input id={currentProfile.profile._id} className="form-control my-3 col-9 col-md-10" type="text" name="firstName" onChange={(e)=>{setprofileState({...profileState,name:e.target.value})}} value={profileState.name} placeholder="Profile name" required/>
					</div>
					<div className='row justify-content-center col-md-6 pr-0'>
						<button type='submit' className='btn col-12 my-1 profile-form-btn'>{location.pathname !== "/new-profile" ? "Update" : "Submit"}</button>
					</div>
				</form>
			</div>
		</div>	
	)
}


export default EditProfileWhosWatching;