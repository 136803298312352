import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const StyledEpisodeCard = styled(Card)(({ theme, active }) => ({
	backgroundColor: active ? '#2D132C' : 'rgba(30, 30, 30, 0.8)',
	color: active ? 'white' : theme.palette.text.primary,
	cursor: 'pointer',
	transition: 'all 0.3s ease',
	'&:hover': {
		backgroundColor: '#2D132C',
		color: 'white',
		transform: 'translateY(-4px)',
		boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
	},
	display: 'flex',
	marginBottom: theme.spacing(1),
	height: '100px',
}));

const EpisodeSelector = ({ 
	seasons, 
	selectedSeason, 
	selectedEpisode, 
	onSeasonChange, 
	onEpisodeSelect 
}) => {
	// Convert seasons object to array for display
	const seasonNumbers = Object.keys(seasons).map(season => parseInt(season));
  
	const handleSeasonChange = (event, newValue) => {
		onSeasonChange(newValue);
	};

	//optimize thumbnail loading
	const getThumbnailUrl = (thumbnailPath) => {
		if (!thumbnailPath) {
			return `${process.env.REACT_APP_INPUT_BUCKET_IMAGES_CF}/assets/images/placeholder.jpg`;
		}

		// Check if it's a full URL or just a path
		if (thumbnailPath.startsWith('http')) {
			// For full URLs, use a proxy or direct loading with reduced headers
			return thumbnailPath;
		} else {
			// For relative paths
			return `${process.env.REACT_APP_INPUT_BUCKET_IMAGES_CF}/${thumbnailPath}`
		}
	};

	return (
		<Box sx={{ width: '100%', bgcolor: 'rgba(0, 0, 0, 0.4)', p: 2, borderRadius: 1 }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={selectedSeason}
					onChange={handleSeasonChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="season tabs"
				>
					{seasonNumbers.map(season => (
						<Tab 
							key={season} 
							label={`Season ${season}`} 
							value={season}
							sx={{ color: 'white' }}
						/>
					))}
				</Tabs>
			</Box>
			
			<Grid container spacing={2} sx={{ mt: 2 }}>
				{seasons[selectedSeason]?.map((episode, index) => (
					<Grid item xs={12} sm={6} md={4} key={episode.video_episode_id}>
						<StyledEpisodeCard 
							active={episode.episode_number === selectedEpisode}
							onClick={() => onEpisodeSelect(selectedSeason, episode.episode_number)}
						>
							<CardMedia
								component="img"
								sx={{ width: 150 }}
								image={getThumbnailUrl(episode.episode_thumbnail) || '/default-episode-thumbnail.jpg'}
								alt={`Episode ${episode.episode_number}`}
							/>
							<Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%' }}>
								<CardContent sx={{ flex: '1 0 auto', p: 1 }}>
									<Typography component="div" variant="subtitle1">
										Episode {episode.episode_number}
									</Typography>
									<Typography variant="body2" color="text.secondary" sx={{ 
										overflow: 'hidden', 
										textOverflow: 'ellipsis',
										display: '-webkit-box',
										WebkitLineClamp: 2,
										WebkitBoxOrient: 'vertical',
									}}>
										{episode.episode_name || `Episode ${episode.episode_number}`}
									</Typography>
								</CardContent>
								{episode.episode_number === selectedEpisode && (
									<Box sx={{ 
										position: 'absolute', 
										right: 8, 
										top: 8, 
										bgcolor: 'rgba(0,0,0,0.6)', 
										borderRadius: '50%',
										width: 32,
										height: 32,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}>
										<PlayArrowIcon sx={{ color: 'white' }} />
									</Box>
								)}
							</Box>
						</StyledEpisodeCard>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default EpisodeSelector;