// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bg-shortsPage{
    background: url("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png"), #311430; 
    background-position: left;
    position: static;
    height: 150vh;
    background-repeat: no-repeat;
    background-size: cover;
}
.mt-20{
    margin-top: 10px;
}
@media(max-width:600px){
    .bg-shortsPage{
        background: url("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png"), #311430; 
        background-position: left;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover; 
    }
    .mt-20{
        margin-top: 100px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Shorts/Shorts.css"],"names":[],"mappings":";AACA;IACI,oHAAoH;IACpH,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,gBAAgB;AACpB;AACA;IACI;QACI,oHAAoH;QACpH,yBAAyB;QACzB,aAAa;QACb,4BAA4B;QAC5B,sBAAsB;IAC1B;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["\n.bg-shortsPage{\n    background: url(\"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png\"), #311430; \n    background-position: left;\n    position: static;\n    height: 150vh;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n.mt-20{\n    margin-top: 10px;\n}\n@media(max-width:600px){\n    .bg-shortsPage{\n        background: url(\"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png\"), #311430; \n        background-position: left;\n        height: 100vh;\n        background-repeat: no-repeat;\n        background-size: cover; \n    }\n    .mt-20{\n        margin-top: 100px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
