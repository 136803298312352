import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import LinearProgress, {linearProgressClasses,} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import $ from "jquery";
import TopNavBar from "../Nav/TopNavBarV2";
import NavDrawer from "../Nav/NavDrawer";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Toast from "../Notification/Toast";
import MovieSliderSimilarMovies from "../Slider/MovieSliderSimilarMovies";
import SeasonDialogue from "./Seasons";
import Footer from "../Footer/Footer";
import BottomNav from "../Nav/BottomNav";
import "../MoviesPage/MoviesPage.css";
import "./MovieDesc.css";
import { navigate } from "wouter/use-location";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 0,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[200],
		...(theme.palette.mode === "dark" && {
			backgroundColor: theme.palette.grey[800],
		}),
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 0, // Changed the border radius for the bar
		backgroundColor: "#C92941", // Set the desired background color for the progress indicator
	},
}));

const MovieDescPage = (props) => {
	const [isNavNotShowing, showNav] = useState(true);
	const [isNavBarSearch, setNavBarSearch] = useState({ active: true });
	const [isFooter, setFooter] = useState({
		footerActive: true,
		bottomNavActive: false,
	});
	const [isAlert, setAlert] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [playback, setPlayBack] = useState(null);
	const [message, setMsg] = useState("");
	const [showSeasonDialogue, setSeasonDialogue] = useState(false)
	const [selectedSeason, setSelectedSeason] = useState(null)

	const profileId = localStorage.getItem("selected_prof_id");
	const regionObj = localStorage.getItem("region") ? JSON.parse(localStorage.getItem("region")) : {};
	const region = regionObj[profileId];

	const location = useLocation();
	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const currentUser = localStorage.getItem("currentUser");
	const currentProfile = localStorage.getItem("selected_prof_id");
	const profileName = localStorage.getItem("profile_name");

	let content_id = location.state?.content_id;
	const content_name = location.state?.content_name || "No Title";
	const description = location.state?.description || "No description";
	const length = location.state?.length || "No movie time length";
	const actors = location.state?.actors || [];
	const header_image = location.state?.header_image ||"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1708159982/default_content_header_uvxcnu.png";

	const isSeries = location.state?.isSeries
	const seasons = location.state?.seasons?.length > 0 ? location.state?.seasons[0] : []

	const { t } = useTranslation();

	useEffect(() => {
		const fetchPlayback = async () => {
			try {
				const response = await axios_.get(`/${currentUser}/content/${content_id}/stream`);
				const { data } = response;
				if (data && data.content.playBack.length > 0) {
					const playbackData = data.content.playBack.find((x) => x.contentId === content_id);
					if (playbackData) {
						setPlayBack(playbackData);
					}
				} else {
					console.log("No playback found in the database");
				}
			} catch (error) {
				console.error("Error fetching playback:", error);
			}
		};

		// Call the fetch function once on initial component mount
		fetchPlayback();

		// Set up the interval to check the database every 60 seconds
		const intervalId = setInterval(fetchPlayback, 60000);

		// Cleanup function to clear the interval when the component is unmounted
		return () => clearInterval(intervalId);
	}, [currentUser, content_id]);

	const watchingStyle = {
		text:playback?.position !== 0 && playback !== null ? "Continue watching": "Watch",
		buttonPaddingX: playback?.position !== 0 && playback !== null ? "px-3" : "px-5",
		addToListbuttonPosition: playback?.position !== 0 && playback !== null? "add-to-list-btn-dsc" : "add-to-list-btn-dsc",
	};

	let showNavigation = () => {
		showNav(!isNavNotShowing);
	};

	const convertLengthMilliToTime = (length) => {
		const totalMinutes = Math.floor(length / 60000); // 1 minute = 60,000 milliseconds
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		if (!isNaN(hours) && !isNaN(minutes)) {
			return { hours, minutes };
		}

		return { hours: 0, minutes: 0 };
	};

	const convertLengthSecondsToTime = (length) => {
		const totalMinutes = Math.floor(length / 60); // 1 minute = 60seconds
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		if (!isNaN(hours) && !isNaN(minutes)) {
			return { hours, minutes };
		}

		return { hours: 0, minutes: 0 };
	};

	const addToList = async (e) => {
		e.preventDefault();
		try {
			const res = await axios_.post(
				`/${currentUser}/user/${currentProfile}/my-list/${content_id}`
			);
			if (res.data.success) {
				setMsg(res.data.message);
				setAlert(true);
			} else {
				setMsg(res.data.message);
				setAlert(true);
			}
		} catch (e) {
			setAlert(false);
			console.log(e);
		}
	};

	const handleSeasonClick = (seasonNumber) => {
		setSelectedSeason(seasonNumber);
		setSeasonDialogue(true);
	};

	const handleCloseDialog = () => {
		setSeasonDialogue(false);
	};

	const handleContentClick=(e)=>{
		if (isSeries && Object.keys(seasons).length > 0) {
			e.preventDefault();
			handleSeasonClick(1);
		}
	}

	useEffect(() => {
		window.scroll(0, 0);

		if ($(window).width() <= 600) {
			setNavBarSearch({ ...isNavBarSearch, active: false });
		} else {
			setNavBarSearch({ ...isNavBarSearch, active: true });
		}
	}, []);

	const windowWidth = $(window).width() <= 600;

	useEffect(() => {
		//Determine Mobile or not
		if ($(window).width() <= 600) {
			setNavBarSearch({ ...isNavBarSearch, active: false });
			setFooter({
				...isFooter,
				footerActive: false,
				bottomNavActive: true,
			});
		} else {
			setNavBarSearch({ ...isNavBarSearch, active: true });
			setFooter({
				...isFooter,
				footerActive: true,
				bottomNavActive: false,
			});
		}
	}, []);

	/**
	 *
	 * @param {*} text - The content description
	 * @param {*} maxLineLength The maximum length of characters to have on each line
	 * @returns
	 */
	const splitByWords = (text, maxLineLength) => {
		const words = text.split(" "); // Split by words
		const lines = [];
		let currentLine = "";

		words.forEach((word) => {
			if ((currentLine + word).length <= maxLineLength) {
				currentLine += (currentLine ? " " : "") + word; // Add the word to the current line
			} else {
				lines.push(currentLine);
				currentLine = word; // Start a new line with the current word
			}
		});

		if (currentLine) {
			lines.push(currentLine); // Push the last line
		}

		return lines;
	};
	return (
		<div className="bg-moviesPage">
			{isNavBarSearch.active ? (
				<TopNavBar
					profile={profileName}
					region={region}
					navToggle={showNavigation}
				></TopNavBar>
			) : null}
			<span className="nav_drawer">
				{isNavNotShowing ? null : <NavDrawer closeDrawer={showNavigation} />}
			</span>

			{isAlert ? <Toast message={message} paySuccess={isAlert} /> : null}

			<div class="movie-container" style={{position: 'relative', background: `url('${header_image}') center center / cover no-repeat`}}>
				<div class="movie-content">
					<p className="movie-title">{content_name}</p>
					<p className="movie-time">
						{convertLengthSecondsToTime(length).hours > 0 ||
						convertLengthSecondsToTime(length).minutes > 0
							? `${convertLengthSecondsToTime(length).hours}h ${convertLengthSecondsToTime(length).minutes
							}m`
							: `${Math.round(length)}s`}
					</p>
					{/* Description */}
					<div className="movie-description">
						<p className="text-white">{description}</p>
					</div>
					<div className="d-none movie_description-text ml-2 ml-md-5">
						{(() => {
						//description.match(/.{1,60}/g)
							const splitDescription = description != undefined ? splitByWords(description, 60) || []: ""; // maximum length of each line 60 chars each
							if (splitDescription.length > 0) {
								const isLong = splitDescription.join("").length > 250; // Check total length of characters
								// Restrict to 250 characters and split into lines of 60 chars each if > 250
								// splitDescription.join("").substring(0, 250).match(/.{1,60}/g)
								const displayDescription = isLong ? splitByWords(description.substring(0, 250), 60) : splitDescription;
								const fullDescription = description || "";
								return (
									<>
										{displayDescription.map((sentence, index) => (<p key={index}>{sentence}</p>))}
										{isLong && (
											<p
												style={{
													display: "inline",
													cursor: "pointer",
													color: "white",
												}}
												onMouseEnter={() => setIsHovered(true)}
												onMouseLeave={() => setIsHovered(false)}
											>
											... {/* Ellipses */}
												{isHovered && (
													<span
														style={{
															position: "absolute",
															background: "#422B41",
															border: "1px solid #ccc",
															padding: "10px",
															width: "400px",
															zIndex: 10,
															color: "white",
														}}
													>
														{fullDescription}
													</span>
												)}
											</p>
										)}
									</>
								);
							}
							return null;
						})()}
					</div>
					{/* Buttons */}
					<div className="button-container d-none d-md-flex">
						<Link
							onClick={handleContentClick}
							to={`/watch/${content_id}/play`}
							state={{ contentId: content_id, actors: actors }}
							className={`watch-btn-dsc`}
						>
							<PlayArrowRoundedIcon
								className={`${playback !== null ? "" : ""}`}
							/>
							{watchingStyle.text}
						</Link>

						<Link
							to={"#"}
							className={`${watchingStyle.addToListbuttonPosition} `}
							onClick={addToList}
						>
							<AddRoundedIcon className="" />
							{t("addToList")}
						</Link>

					</div>
					{/* Mobile */}
					<div className="button-container d-block d-md-none">
						<Link
							onClick={handleContentClick}
							to={`/watch/${content_id}/play`}
							state={{ contentId: content_id, actors: actors }}
							className={`watch-btn-dsc mb-2`}
						>
							<PlayArrowRoundedIcon className={`${playback !== null ? "" : ""}`}/>
							<span>{watchingStyle.text}</span>
						</Link>

						<Link
							to={"#"}
							className={`${watchingStyle.addToListbuttonPosition} `}
							onClick={addToList}
						>
							<AddRoundedIcon className="" />
							<span>{t("addToList")}</span>
						</Link>
					</div>
					{/* Mobile */}
				</div>
				{/* all work in progress */}
				{playback?.position !== 0 && playback !== null ? (
					<>
						<BorderLinearProgress
							variant="determinate"
							value={playback?.position}
							color="inherit"
							style={{
								position: "absolute", // Fix the progress bar at the bottom
								bottom: 0, // Align it to the bottom
								left: 0, // Align it to the left edge
								right: 0, // Make it span the full width
								zIndex: 9999
							}}
						/>
						{/* <ProgressBar
								now={playback?.position}
								max={playback?.totalDuration || playback.timeUpdated}
							/> */}
					</>
				) : null}
			</div>

			<div className="container-fluid">
				<div className="row mt-0 mt-md-5">
					{isSeries && Object.keys(seasons).length > 0 && (
						<div className="col-md-12">
							<h1 className="row-header-title mb-md-2 mt-md-2">Seasons</h1>
						</div>)
					}
					{/* Seasons */}
					<div className="row ml-md-3">
						{
							isSeries && Object.keys(seasons).length > 0 ? (<>
								{Object.keys(seasons).map((season, index)=>{
									return <div key={index} className="movie-cast-name py-2">
										<p onClick={()=>handleSeasonClick(season)} className="season-btn btn ml-4">Season {season}</p>
									</div>
								})}
							</>) :  null
						}
					</div>

					{showSeasonDialogue ? (
						<SeasonDialogue 
							showSeason={showSeasonDialogue} 
							seasons={seasons}
							seasonNumber={selectedSeason} 
							contentName={content_name}
							contentId={content_id}
							episodes={seasons[selectedSeason]}
							onClose={handleCloseDialog} 
						/>
					) : null}
				</div>

				<div className="row mt-0 mt-md-5">
					<div className="col-md-12">
						<h1 className="row-header-title mb-md-4 mt-md-2">Cast</h1>
					</div>
					{/* Cast */}
					<div className="row ml-md-3">
						{actors.length > 0 ? (
							actors.map((actor, pos) => {
								return (
									<div key={pos} className="ml-4 ml-md-3">
										{/* actor image */}
										<img
											className="movie-cast"
											src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1693470115/test_avatar_agwzgp.jpg"
											alt=""
										/>
										<p className="movie-cast-name py-2">{actor}</p>
									</div>
								);
							})
						) : (
							<div>No actors</div>
						)}
					</div>
				</div>

				<div className="row py-md-4 similarMovies">
					<div className="col-md-12">
						<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">
							Similar Anime
						</h1>
					</div>
				</div>

				<MovieSliderSimilarMovies actors={actors} />

				<div className="row py-md-4 similarMovies">
					<div className="col-md-12">
						<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">
							Similar Actor Movies
						</h1>
					</div>
				</div>

				<MovieSliderSimilarMovies actors={actors} />
			</div>

			{isFooter.footerActive ? (
				<Footer />
			) : (
				<BottomNav className="d-block d-md-none d-sm-block" />
			)}
		</div>
	);
};

export default MovieDescPage;
