import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import videojs from 'video.js';
import "video.js/dist/video-js.css";
import "videojs-mux";
import AfrowatchVideoPlayer from "./AfrowatchVideoJS";
import MovieSliderSimilarMovies from "../Slider/MovieSliderSimilarMovies";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../apiClient";
import { resolveSourceTypeFromVideoType } from "./controls/_utils";
import TopNavBar from "../Nav/TopNavBarV2";
import BottomNav from "../Nav/BottomNav";
import "../MoviesPage/MoviesPage.css";
import "./MediaPlayer.css";

const FeaturedMediaPlayerPage = (props) => {
	const location = useLocation();
	const [mediaStream, setMediaStream] = useState({});
	const [featuredStream, setFeaturedStream] = useState();
	const [isMoviesComp, setComp] = useState(false);
	const [isNavNotShowing, showNav] = useState(true);
	const [pageloading, setPageLoading] = useState(false);
	const playerRef = useRef(null);
	const videoRef = useRef(null)

	const userId = localStorage.getItem("currentUser"); //
	const profileId = localStorage.getItem("selected_prof_id");
	const profileName = localStorage.getItem("profile_name");
	const regionObj = localStorage.getItem("region") ? JSON.parse(localStorage.getItem("region")): {};
	const region = regionObj[profileId];
	const contentId = location.state?.contentId || "no-id";
	const actors = location.state?.actors || [];

	// When its the Default Page (Movies page)
	const regex = /^\/profile\/[a-f0-9]{24}\/watch\/?$/;
	let featurePage = regex.test(window.location.pathname)
	//

	function isMovieComponent() {
		const check = location.pathname.split("/");
		return !!(check.indexOf("watch") && check.indexOf("profile") !== -1);
	}

	const getDisplayContent = async () => {
		try {
			let contentIds = [];
			const contentReq = `/content/${region}/trending`;

			const getUrl =(contentId)=> {
				return `${localStorage.getItem("currentUser")}/content/${contentId}/stream`;
			}

			// Fetch trending content
			const res = await axiosInstance.get(contentReq);
			if (res.data.status === 200) {
				for (const content of res.data.content) {
					contentIds.push(content["content_id"]);
				}
				
				// Helper function to get a random URL
				const setRandomUrl = () => {
					const randomIndex = Math.floor(Math.random() * contentIds.length);
					const randomContentId = contentIds[randomIndex];
					const url = getUrl(randomContentId);
					// console.log(`Setting URL to: ${url}`);
					return url;
				};
		
				// Function to update featured stream
				const updateFeaturedContent = async () => {
					try {
						const URL = setRandomUrl();
						const resTwo = await axiosInstance.get(URL);

						if (resTwo.data.status === 200) {
							// console.log(resTwo.data.content.stream["hls_stream_path"]);

							setFeaturedStream(resTwo.data.content.stream);
							localStorage.setItem("featured_stream_id", URL.split("/")[2]);
						} else {
							console.error("Unexpected response status:", resTwo.data.status);
						}
					} catch (error) {
						console.error("Error fetching featured content:", error);
					}
				};
		
				// Set initial featured content
				await updateFeaturedContent();
		
				// Periodically update featured content every 2 minutes
				const intervalTime = 120000; // 1 minutes 60,000
				const startInterval = async () => {
					await updateFeaturedContent();
					setTimeout(startInterval, intervalTime);
				};

				startInterval();
			}
		} catch (error) {
			console.error("Error fetching trending content:", error);
		}
	};

	useEffect(() => {
		getDisplayContent()
	}, [featurePage])

	useEffect(() => {
		setComp(isMovieComponent());

		//For video metadata
		getContentById()
	}, []);

	const getContentById = async () => {
		let res = await axiosInstance.get(`/content/${contentId}`)
		const { data } = res
		if (res.data.status === 200) {
			videoRef.current = data.data
		}
	}

	useEffect(() => {
		if(featuredStream){
			setMediaStream(featuredStream)
		}
	}, [contentId, featuredStream])

	//Video.js doesn't automatically pick up changes to the source in React. 
	//When videoJsOptions is updated, the player needs to explicitly handle the source change
	useEffect(() => {
		if (playerRef.current && mediaStream?.hls_stream_path) {
			const player = playerRef.current;

			// Stop the current video
			player.pause();

			// Update the player source with the new media stream
			player.src({
				src: `${process.env.REACT_APP_BASE_STREAM_URL}/${mediaStream.hls_stream_path}`,
				type: mediaStream.video_content_type ? resolveSourceTypeFromVideoType(mediaStream.video_content_type) : "application/x-mpegURL",
			});

			// Play the new video
			player.load(); // Ensures the player reloads the new source
			player.play().catch((error) => {
				console.error("Error playing video:", error);
			});
		}
	}, [mediaStream]);
    

	const [isFooter, setFooter] = useState({ footerActive: true,bottomNavActive: false,});
	useEffect(() => {
		if ($(window).width() <= 600) {
			setFooter({
				...isFooter,
				footerActive: false,
				bottomNavActive: true,
			});
		} else {
			setFooter({
				...isFooter,
				footerActive: true,
				bottomNavActive: false,
			});
		}
	}, [])

	let showNavigation = () => showNav(!isNavNotShowing);

	// NOTE: https://assets.afrowatch.co is not a production CF distribution url
	const videoJsOptions = {
		autoplay: featurePage ? true : false,
		controls: true,
		responsive: true,
		fluid: true,
		enableSmoothSeeking: true,
		nativeTextTracks: true,
		preloadTextTracks: true,
		spatialNavigation: {
			enabled: true,
			horizontalSeek: true
		},
		userActions: {
			click: true,
			hotkeys: function (event) {
				if (event.which === 86 || event.which === 70) {
					event.preventDefault();
					if (this.isFullscreen()) {
						this.exitFullscreen();
					} else {
						this.requestFullscreen();
					}
				}
				// Toggle mute/unmute when pressing the M key (77)
				if (event.which === 77) {
					event.preventDefault();
					this.muted(!this.muted());
				}
				// Check if the spacebar is pressed
				if (event.which === 32) {
					event.preventDefault(); // Prevent default spacebar action
					// Toggle play/pause
					if (this.paused()) {
						this.play();
					} else {
						this.pause();
					}
				}
			},
		},
		sources: mediaStream ? [{
			src: mediaStream && mediaStream['hls_stream_path'] ? `${process.env.REACT_APP_BASE_STREAM_URL}/${mediaStream['hls_stream_path']}` : `${process.env.REACT_APP_BASE_STREAM_URL}/default-video-01/Default/HLS/01_720.m3u8`, 
			type: mediaStream && mediaStream['video_content_type'] ? resolveSourceTypeFromVideoType(mediaStream['video_content_type']) : 'application/x-mpegURL'
		}] 
			: 
			[],
		controlBar: {
			skipButtons: {
				forward: 10,
				backward: 10
			},
		},
		muted: featurePage ? true : false,
		// plugins: {
		// 	mux: {
		// 		debug: false,
		// 		disableCookies: true,
		// 		data: {
		// 			env_key: process.env.REACT_APP_MUX_TOKEN || 'k127micvjrhtfa6kbuch63ju8', // required
		// 			// Metadata
		// 			player_name: "Afrowatch Player",
		// 			viewer_user_id: userId, //currently userid not profileId
		// 			viewer_profile_id: profileId,
		// 			viewer_profile_name: profileName,
		// 			// Player Metadata
		// 			player_version: '1.0.0',
		// 			// Video Metadata
		// 			video_id: mediaStream && mediaStream?.stream?.content_id,
		// 			video_stream_type: 'on-demand',
		// 			video_cdn: 'cloud-front',
		// 			video_title: videoRef && videoRef.current?.content_name,
		// 			video_content_type: videoRef && videoRef.current?.content_type,
		// 			//subtitles in our case
		// 			video_language_code: videoRef && videoRef.current?.subtitles.toString(),
		// 			//animators
		// 			video_producer: videoRef && videoRef.current?.animators?.toString(),
		// 			//we store our length in (s)
		// 			video_duration: videoRef && videoRef.current?.length,
		// 			// You can only have 1-10 custom metadata and they have to be Strings
		// 			//Custom 1: Stream id
		// 			custom_1: mediaStream && mediaStream?.stream?.stream_id,
		// 			//Custom 2 : creator id
		// 			//Check if stream content id is in vendor list array, if it is set the creator id
		// 			custom_2: videoRef && videoRef.current?.creator_id
		// 			//Custom 3: video Genre
		// 			//custom_3: videoRef && videoRef.current?.genre
		// 			//Custom 4: Mux playback id, to get playback from mux in portal
		// 		}
		// 	}
		// }
	};
	// Language 
	const defaultLanguage = { code: 'en', label: 'English' };

	let tracks = []
	try {
		tracks = mediaStream && mediaStream['subtitles_list'] ? mediaStream['subtitles_list'] : [
			{
				src: 'https://res.cloudinary.com/dz5b76kzm/raw/upload/v1711535218/subtitles/en_htuk8r.vtt',
				kind: 'subtitles',
				srclang: 'en', // Source language
				label: 'English',
				//default: true,
			},
			{
				src: 'https://res.cloudinary.com/dz5b76kzm/raw/upload/v1711535187/subtitles/yo_lyl7dp.vtt',
				kind: 'subtitles',
				srclang: 'yb', // Source language
				label: 'Yoruba',
			},
		]
	} catch (error) {
		console.log(error)
	}

	const handlePlayerReady = (player) => {
		playerRef.current = player;
		// You can handle player events here, for example:
		player.on('waiting', () => {
			videojs.log('player is waiting');
		});

		player.on('dispose', () => {
			videojs.log('player will dispose');
		});
	};

	useEffect(() => {
		if (playerRef.current) {
			const player = playerRef.current;

			player.on("error", () => {
				console.error("An error occurred.");
				player.poster("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1733305731/Error_screen_fo6msm.png");
				player.src([]); // Clear the video source
			});

			return () => {
				player.off("error");
			};
		}
	}, []);    

	return (
		<div className="video-player-page">
			{isMoviesComp ? null : <TopNavBar region={region} profile={profileName} navToggle={showNavigation} />}

			{
				pageloading ?
					<div className="no-video">
						<LinearProgress disableShrink className="d-block m-auto" color="secondary" fourColor style={{}} />
						<div data-vjs-player>
							<div className="video-js" />
						</div>
					</div>
					:
					<AfrowatchVideoPlayer contentName={videoRef.current?.content_name} streamData={mediaStream} options={videoJsOptions} onReady={handlePlayerReady} tracks={tracks} language={defaultLanguage} navDrawer={props.navDrawer} />
			}

			{isMoviesComp ? null : (
				<div>
					<div className="container-fluid">
						<div className="row mt-5">
							<div className="col-md-12">
								<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">
									Similar Animation
								</h1>
							</div>
						</div>

						{actors.length > 0 ? (
							<MovieSliderSimilarMovies actors={actors} />
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="text-center text-white">
											No similar movies found
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			{isMoviesComp ? null : (isFooter.footerActive ? <Footer /> : (<BottomNav />))}
		</div>
	);
};

export default FeaturedMediaPlayerPage;