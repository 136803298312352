// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-termsOfUse {
    background: url("https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png"), #311430;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    /* padding-top: 5.4rem; */
    background-color: #2D132C;
    height: 100vh;
}

@media (max-width:600px) {
    .bg-termsOfUse {
        height: 100vh;
    }
}

.TermsOfService-Container {
    color: white;
    height: 62vh;
    padding: 20px;
    overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Legal/TermsOfUse.css"],"names":[],"mappings":"AAAA;IACI,oHAAoH;IACpH,4BAA4B;IAC5B,sBAAsB;IACtB,yBAAyB;IACzB,yBAAyB;IACzB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,cAAc;AAClB","sourcesContent":[".bg-termsOfUse {\n    background: url(\"https://res.cloudinary.com/www-softroniiks-com/image/upload/v1712868110/aw_bg_vi0dpy.png\"), #311430;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: left;\n    /* padding-top: 5.4rem; */\n    background-color: #2D132C;\n    height: 100vh;\n}\n\n@media (max-width:600px) {\n    .bg-termsOfUse {\n        height: 100vh;\n    }\n}\n\n.TermsOfService-Container {\n    color: white;\n    height: 62vh;\n    padding: 20px;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
