import {useEffect, useState} from "react";
import {setProfileStreamPlayBackData, uploadUserPlayBackData, getContentPlayBackDataFromDB} from "./controls/_playback";
import {Chalk} from 'chalk'
import videojs from 'video.js';
import {PLAYBACK_TRACKING_OPTS} from "../constants";
import {formatVideoTime, TimeDifferenceCalc} from "./controls/_utils";
import { axiosInstance } from "../../apiClient";

// NOTES:
// 15 seconds stores in Local storage
// 1 minute to DB
// 2. The timeupdated event fires too frequently for the purpose of memorizing progress hence down-sampling the event is desired

const PlayBackTracker = (props) => {
	const player = props.player
	const userId = localStorage.getItem('currentUser')
	const profileId = localStorage.getItem("selected_prof_id");
	const contentId = props.streamData.stream?.content_id ? props.streamData.stream.content_id: null

	const chalk = new Chalk()

	const [statistics, setStats] = useState({viewCount:0, secondsViewed:0, secondPaused:0, pauseCount:0})

	const addToWatchingNow = async () => {
		//console.log(userId, profileId, contentId)
		try {
			const res = await axiosInstance.put(`/${userId}/user/profile/${profileId}/watching_now/${contentId}`, {position: player.currentTime(), totalDuration:player.duration()});
			console.log(res.data)
			if(res.data.status === 200){
				console.log(res.data.message)
			}
		} catch (error) {
			console.log(error)
		}
	};

	useEffect(() => {
		try{
			getContentPlayBackDataFromDB(userId, profileId, props.streamData.stream.content_id)
		}catch(err){
			console.log(`NO CONTENT ID FOUND FOR PLAYBACK`)
			console.log(err)
		}

		if (player && player.isReady_) {
			const updateTimeCalculator = TimeDifferenceCalc(PLAYBACK_TRACKING_OPTS.playbackDataUpdateInterval)
			const uploadTimeCalculator = TimeDifferenceCalc(PLAYBACK_TRACKING_OPTS.playbackDataUploadInterval)

			player.on('play', () => {
				console.log('Video has started playing!');
				// Add to watching now
				addToWatchingNow()
			});

			player.on("timeupdate", function () {
				const now = Date.now()
				if (updateTimeCalculator.checkIfAboveThresholdDifference(now)) {
					updateTimeCalculator.setPreviousTime(now)
					player.trigger("setPlaybackDataInLocalStorage")
				}
				if (uploadTimeCalculator.checkIfAboveThresholdDifference(now)) {
					uploadTimeCalculator.setPreviousTime(now)
					player.trigger("uploadPlaybackToDb")
					// So the content position updates
					addToWatchingNow()
				}
			})

			player.on("setPlaybackDataInLocalStorage",  () => {
				let contentId = props.streamData.stream.content_id
				let currentPlayerTime = player.currentTime()
				const totalPlayerTime = player.duration(); //total length of content
				
				setProfileStreamPlayBackData(profileId, contentId, currentPlayerTime, totalPlayerTime)
				console.log(`Stored playback for stream ${contentId}, playback progress at ${formatVideoTime(currentPlayerTime)} of ${formatVideoTime(player.duration())}`)
			})

			player.on("uploadPlaybackToDb", async () => {
				if(props.streamData.stream.content_id){
					const isSuccessful = await uploadUserPlayBackData(userId, profileId, props.streamData.stream.content_id)
					console.log(isSuccessful ? chalk.green("Uploaded playback data successfully.") : chalk.red('playback data failed to upload'))
				}
			})
		}
	}, [props.player.isReady_])

	return <></>
}
export default PlayBackTracker;