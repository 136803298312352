// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-3bmhjh-MuiPaper-root-MuiPopover-paper{
    border-radius: 15px !important;
    padding: 5px;
}
.css-doetwc-MuiInputBase-root-MuiOutlinedInput-root{
    font-family: 'Poppins', sans-serif;
}
.css-10hburv-MuiTypography-root{
    font-family: 'Poppins', sans-serif;
}
.rotate-up {
  /* transform: rotate(316deg); */
  background-color: rgb(81, 35, 80);
  border-radius: 100px;
  padding: 2px 2px 2px 2px;
  margin-top: -.5rem;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  color: #98A2B3 !important;
  height: 1em !important;
  padding: 12px 14px;
}


.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1.2px;
  overflow: hidden;
  min-width: 0%;
  border-color: #98A2B3 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Shorts/dialog.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;AAChB;AACA;IACI,kCAAkC;AACtC;AACA;IACI,kCAAkC;AACtC;AACA;EACE,+BAA+B;EAC/B,iCAAiC;EACjC,oBAAoB;EACpB,wBAAwB;EACxB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;AACpB;;;AAGA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,SAAS;EACT,OAAO;EACP,SAAS;EACT,cAAc;EACd,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,gCAAgC;AAClC","sourcesContent":[".css-3bmhjh-MuiPaper-root-MuiPopover-paper{\n    border-radius: 15px !important;\n    padding: 5px;\n}\n.css-doetwc-MuiInputBase-root-MuiOutlinedInput-root{\n    font-family: 'Poppins', sans-serif;\n}\n.css-10hburv-MuiTypography-root{\n    font-family: 'Poppins', sans-serif;\n}\n.rotate-up {\n  /* transform: rotate(316deg); */\n  background-color: rgb(81, 35, 80);\n  border-radius: 100px;\n  padding: 2px 2px 2px 2px;\n  margin-top: -.5rem;\n}\n.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{\n  color: #98A2B3 !important;\n  height: 1em !important;\n  padding: 12px 14px;\n}\n\n\n.css-1d3z3hw-MuiOutlinedInput-notchedOutline {\n  text-align: left;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  top: -5px;\n  left: 0;\n  margin: 0;\n  padding: 0 8px;\n  pointer-events: none;\n  border-radius: inherit;\n  border-style: solid;\n  border-width: 1.2px;\n  overflow: hidden;\n  min-width: 0%;\n  border-color: #98A2B3 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
