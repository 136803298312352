// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-select-box {
  border: 1px solid #DA70D6;
  border-radius: 8px;
  cursor: pointer;
  padding: 1rem;
  margin-bottom: 24px;
}

.search-input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  outline: none;
}

.dropdown-arrow {
  color: rgba(255, 255, 255, 0.7);
}

.search-dropdown {
  background: #3C3558;
  border-radius: 0 0 8px 8px;
  margin-top: 2px;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 24px;
}

.search-dropdown-item {
  padding: 1rem;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
}

.search-dropdown-item:hover {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/MoviesPage/SearchDropdown.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,+BAA+B;EAC/B,WAAW;EACX,aAAa;AACf;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;EAC1B,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".search-select-box {\n  border: 1px solid #DA70D6;\n  border-radius: 8px;\n  cursor: pointer;\n  padding: 1rem;\n  margin-bottom: 24px;\n}\n\n.search-input-wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.search-input {\n  background: transparent;\n  border: none;\n  color: rgba(255, 255, 255, 0.7);\n  width: 100%;\n  outline: none;\n}\n\n.dropdown-arrow {\n  color: rgba(255, 255, 255, 0.7);\n}\n\n.search-dropdown {\n  background: #3C3558;\n  border-radius: 0 0 8px 8px;\n  margin-top: 2px;\n  max-height: 200px;\n  overflow-y: auto;\n  margin-bottom: 24px;\n}\n\n.search-dropdown-item {\n  padding: 1rem;\n  cursor: pointer;\n  color: white;\n  transition: background-color 0.3s;\n}\n\n.search-dropdown-item:hover {\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
